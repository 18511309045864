// For desktop
@media #{$large-up} {
  .nav {
    #options-menu,
    #search-menu,
    #minicart-menu {
      display: flex !important;
    }

    .active-options & #options-menu {
      &::after {
        visibility: visible;
      }

      z-index: 0;
      left: 0;
    }

    #options-menu {
      .nav__menu-content {
        .nav__close {
          display: flex;
          justify-content: flex-end;
        }

        ul {
          position: absolute;
          width: calc(100% - 48px);
          transition: $anime-200;
        }
      }
    }

    .active-search & #search-menu {
      z-index: 0;
      right: 0;
      width: 360px !important;

      &::after {
        visibility: visible;
      }

      .nav__menu-content {
        .nav__close {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .nav-category {
      font-size: 13px;
      color: #d6d6d6;
      margin-bottom: 13px;
    }

    .second {
      margin-top: 40px;
    }
  }
}

// For mobile

.nav-category {
  font-size: 13px;
  color: #d6d6d6;
  margin-bottom: 13px;
  margin-top: 10px;
}

.second {
  margin-top: 30px;
}

.nav {
  &__menu {
    &-left {
      .active-navigation & {
        left: 0;
      }
    }
  }

  #options-menu,
  #search-menu,
  #minicart-menu {
    display: none;
  }
}

.nav__menu-left {
  z-index: 2000;
}
