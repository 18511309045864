$height: 500px;

// For mobile
@media #{$small-medium} {
  .forgot-password {
    .form-wrap {
      p {
        width: 80% !important;
      }
    }
  }
}

// Common
.forgot-password {
  @include form(300px, 80%, $white, $white);

  .form-bg {
    @include min-viewport-height;
    height: $height;
    background: url('/assets/images/homepage/desktop/hero_banner/VE_Homepage_4.jpg') no-repeat center;
    background-size: cover;
  }

  .form-mask {
    @include min-viewport-height;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $height;
    background-color: $black;
    opacity: .6;
  }

  .form-wrap {
    @include min-viewport-height;
    position: absolute;
    height: $height;

    h1 {
      margin: 0;
    }
  }
}
