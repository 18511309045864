// Hero carousel
.slide__hero-1 {
  @media #{$large-up} {
    background-image: url('/assets/images/homepage/desktop/hero_banner/VE_Homepage_2_2-11-20_0495.jpg') !important;
  }
  background-image: url('/assets/images/homepage/mobile/hero_banner/VE_Homepage_2_2-11-20_0495.jpg');
}
.slide__hero-2 {
  background-image: url('/assets/images/homepage/desktop/hero_banner/VE_Homepage_3.jpg');
}
.slide__hero-3 {
  background-image: url('/assets/images/homepage/desktop/hero_banner/VE_Homepage_4.jpg');
}

// Hero carousel meta
.meta__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  
  @media #{$large-up} {
    .meta-title {
      width: 670px !important;
    }

    .meta-text {
      width: 400px !important;
    }
  }

  .meta-title {
    @include h2-white;
    position: absolute;
    z-index: 100;
    top: 50vh;
    transform: translateY(-50%);
    width: 300px;
    margin-bottom: 0;
  }

  .meta-text {
    @include body-small;
    bottom: calc(#{$spacing-xxl-5} + 60px);
    width: 300px;
    margin-top: $spacing-xxl-6;
  }

  .meta-link {
    @include label-white;
    bottom: $spacing-xxl-5;
    background-color: $black;
    margin: $spacing-xl auto $spacing-xxl-6;
    @media #{$medium-up} {
      margin: $spacing-xl 0 $spacing-xxl-6;
    }
  }
}

.rate-section-show-No {
  display: none !important;
}

.rate-section-show-Yes + section {
  display: none !important;
}

// Carousel 1
.slide__1-1 {
  background-image: url('/assets/images/contentblock/photos/VGR_Homepage_1392x1760.png');
}
.slide__1-2 {
  background-image: url('/assets/images/homepage/desktop/restaurant/VE_Homepage_Restaurant1_2-11-20_1439.jpg');
}
.slide__1-3 {
  background-image: url('/assets/images/homepage/desktop/our_place/VE_Homepage_OurPlace1_2-11-20_1163.jpg');
}

// Carousel customisation
.carousel__custom {
  width: 100% !important;

  .carousel__slides {
    height: 400px;
  }

  @media #{$large-up} {
    .carousel__meta {
      top: 50%;
      transform: translateY(-50%);
      height: 60%;
      margin-left: $spacing-xxl-2;
    }

    .meta-title {
      width: 400px !important;
      font-size: 66px !important;
      margin: 0 !important;
    }
    
    .meta-text {
      width: 350px !important;
      bottom: 60px;
    }
  
    .meta-link {
      bottom: 0;
      background-color: $black;
    }
  }

  .meta-title {
    @include h2;
    margin-top: $spacing-xxl-2;
  }
  .meta-text {
    @include body-small;
    margin-top: $spacing-xxl-2;
  }

  .meta-link {
    @include label-white;
    background-color: $black;
    margin-top: $spacing-l;
  }
}

// Zoom twin 1
.card-thumbnail-1 {
  background-image: url('/assets/images/homepage/desktop/restaurant/VE_Homepage_Restaurant2_2-11-20_1299.jpg');
}

.splash-thumbnail-1 {
  background-image: url('/assets/images/homepage/desktop/restaurant/VE_Homepage_Restaurant1_2-11-20_1439.jpg');
}

// Zoom twin 2
.card-thumbnail-2 {
  background-image: url('/assets/images/homepage/desktop/experiences/VE_Homepage_Experiences1_SHOTBYTHOM-0710-2.jpg');
}

.splash-thumbnail-2 {
  background-image: url('/assets/images/homepage/desktop/wine_feature/VE_Homepage_WineFeature1_DSC_0038.jpg');
}

// Zoom twin 3
.card-thumbnail-3 {
  background-image: url('/assets/images/homepage/desktop/wineclub/2019 Project Harvest White.png');
}

.splash-thumbnail-3 {
  @media #{$small-medium} {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: 24px;
  }
  background-image: url('/assets/images/homepage/desktop/wineclub/VE_Homepage_WineClub_CellarDoor_GAV_0174.jpg');
}

// Sticky images
.sticky-image-1 {
  background-image: url('/assets/images/homepage/desktop/our_place/VE_Homepage_OurPlace1_2-11-20_1163.jpg');
}

// Journal scroll
@media #{$large-up} {
  .journal-scroll {
    .journal-title {
      margin-top: $spacing-xxl-7 !important;
    }

    .journal-gallery {
      margin-top: $spacing-xxl-3 !important;
    }

    .journal-name {
      margin-top: $spacing-xxl-6 !important;
    }

    .journal-entries {
      flex-direction: row !important;
      margin-top: $spacing-xxl-5 !important;

      >div {
        width: 48% !important;
      }

      .entries-column__right {
        .journal-entry {
          &:last-child {
            margin-bottom: $spacing-xxl-3 !important;
          }
        }
      }
    }

    .journal-link {
      position: static !important;
      margin-top: $spacing-xs !important;
      margin-bottom: $spacing-xxl !important;
    }
  }
}

.journal-scroll {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .journal-title {
    @include h2;
    text-transform: uppercase;
    margin-top: $spacing-xxl-3;
    text-align: center;
  }
  
  .journal-text {
    @include body-small;
    width: 310px;
    text-align: center;
    margin-top: $spacing-xxl-2;
  }

  .journal-gallery {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-l;

    >div {
      width: 48.5%;
      height: 0;
      padding-bottom: 60%;
      background-position: center;
      background-size: cover;
    }

    &__left {
      background-image: url('/assets/images/homepage/desktop/our_place/VE_Homepage_OurPlace3.jpg');
    }
    &__right {
      background-image: url('/assets/images/homepage/desktop/our_place/VE_Homepage_OurPlace2_2-11-20_1207.jpg');
    }
  }

  .journal-name {
    @include h2;
    text-align: center;
    text-transform: uppercase;
    margin-top: $spacing-xxl-3;
  }
  
  .journal-entries {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: $spacing-xxl-3;

    >div {
      width: 100%;
    }

    .entries-column__right {
      .journal-entry {
        &:last-child {
          margin-bottom: 75px;
        }
      }
    }

    .journal-entry {
      display: flex;
      flex-direction: column;
      margin-bottom: $spacing-xxl-3;

      .entry-time {
        @include body-small;
      }
      .entry-title {
        @include h3;
        margin-top: $spacing-xxs;
      }
      .entry-text {
        @include body-small;
        width: 95%;
        height: 60px;
        overflow: hidden;
        margin-top: $spacing-xxl-2;
      }
      .entry-link {
        @include body-small;
        margin-top: $spacing-m;

        img {
          margin-left: $spacing-xxs;
        }
      }
    }
  }

  .journal-link {
    @include label-white;
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 200px;
    height: 36px;
    text-transform: uppercase;
    text-align: center;
    line-height: 36px;
    background-color: $black;
    margin-top: 0;
    margin-bottom: 0;
  }
}

// For desktop
@media #{$large-up} {
  .twin-static__card {
    margin-top: 0 !important;

    .card-meta {
      .card-title {
        &__large {
          font-size: 66px !important;
          font-weight: 300 !important;
          letter-spacing: -2.5px !important;
          margin-bottom: -50px !important;
        }

        &__wide {
          width: 80% !important;
        }
      }

      .card-thumb {
        margin-top: 0 !important;
      }

      .card-text {
        margin-top: $spacing-xl;
      }

      .card-award {
        margin-bottom: 0;
      }
    }
  }
}

.twin-static__card {
  height: initial;
  margin-top: $spacing-xxl-4;

  .card-meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .card-title {
      @include h2;
      text-align: center;
      text-transform: uppercase;
    }

    .card-thumb {
      display: block !important;
      width: 285px;
      height: 369px;
      background-position: center;
      background-size: contain;
      margin-top: $spacing-m;
      background-repeat: no-repeat;
    }

    .card-text {
      @include body-small;
      display: block !important;
      width: 300px;
      text-align: center;
      margin-top: $spacing-xxl-3;
      margin-bottom: $spacing-xs;
      transition: all .2s ease-in-out .5s;
    }

    .card-cta {
      margin-top: $spacing-m;
      margin-bottom: $spacing-xl;
    }
  }
}

.twin-static__splash {
  height: 100vh;

  .splash-thumb {
    height: 100%;
    background-position: center;
    background-size: cover;
  }
}
