// For desktop
@media #{$large-up} {
  .contact-us__content {
    margin: $spacing-xxl-5 0 0 20%;

    h1 {
      margin-top: 0;
      margin-bottom: $spacing-xxl-7;
    }
    
    .contact-us__wrap {
      > div {
        width: 50%;
        // Based on @mixin min-viewport-height
        @media #{$xlarge-up} {
          min-height: calc(#{$footer-xlarge} - 336px);
        }
        min-height: calc(#{$footer} - 336px);
      }

      &-details {
        margin-right: 8%;

        &-entries {
          display: flex;
          justify-content: space-between;

          > div {
            width: 48%;
          }
        }
      }

      &-image {
        margin-left: 8%;
      }
    }
  }
}

// For mobile
@media #{$small-medium} {
  .contact-us__content {
    margin: $spacing-xxl-3 0 0;

    h1 {
      margin-top: $spacing-xxl-3;
      margin-bottom: $spacing-xxl-5;
    }

    .contact-us__wrap {
      flex-direction: column;

      > div {
        min-height: 370px;
      }

      &-details {
        margin-bottom: $spacing-xl;

        &-entries {
          flex-direction: column;

          > div:first-child {
            margin-bottom: $spacing-s;
          }
        }
      }
    }
  }
}

// Common
.contact-us__wrap {
  display: flex;

  &-details {
    > p {
      margin: 0 0 $spacing-xxl-3;
    }

    &-entries {
      display: flex;

      span {
        @include cta;
        display: block;
        margin-top: $spacing-xl;

        &:first-child {
          margin-top: 0;
        }

        img {
          vertical-align: sub;
          margin-left: $spacing-xxs;
        }
      }

      p {
        @include body-small;
        margin-top: 0;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  &-image {
    background: url('/assets/images/contact_us/VE_Contact_2-11-20_1559.jpg') center;
    background-size: cover;
  }
}
