/**
 * Footer height
 * This is for calculating the page height dynamically when
 * the page content is not tall enough to occupy the view
 * port height, so that there're no white gaps at the end of
 * page.
 */
 $footer-xlarge: calc(100vh - 84px);
 $footer: calc(100vh - 447px);

/**
 * Colours
 */
$white: #ffffff;
$cream-tint: #f9faf9;

$line-grey: #d6d6d6;
$light-grey: #f5f3f3;
$mid-grey: #b8bdb7;
$dark-mid-grey: #72797a;
$dark-grey: #3f4445;

$green: #0f5e34;
$black: #000000;

/**
 * Spacing
 */
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 20px;
$spacing-xl: 24px;
$spacing-xxl: 28px;
$spacing-xxl-2: 32px;
$spacing-xxl-3: 40px;
$spacing-xxl-4: 56px;
$spacing-xxl-5: 64px;
$spacing-xxl-6: 80px;
$spacing-xxl-7: 96px;
$spacing-xxl-8: 120px;
$spacing-xxl-9: 220px;

/**
 * Animation
 */
$anime-200: all 200ms;
$anime-300: all 300ms;
$anime-500: all 500ms;

/**
 * Media Queries
 */
$screen: 'only screen';
$landscape: '#{$screen} and (orientation: landscape)';
$portrait: '#{$screen} and (orientation: portrait)';

$small-up: $screen;
// 0 ~ 640px
$small-only: '#{$screen} and (max-width: 40em)';

// 641px and up
$medium-up: '#{$screen} and (min-width: 40.063em)';
// 641px ~ 1024px
$medium-only: '#{$screen} and (min-width: 40.063em) and (max-width: 64em)';

// 1025px and up
$large-up: '#{$screen} and (min-width: 64.063em)';
// 1024px ~ 1440px
$large-only: '#{$screen} and (min-width: 64.063em) and (max-width: 90em)';

// 1441px and up
$xlarge-up: '#{$screen} and (min-width: 90.063em)';
// 1441px ~ 1920px
$xlarge-only: '#{$screen} and (min-width: 90.063em) and (max-width: 120em)';

// 1921px and up
$xxlarge-up: '#{$screen} and (min-width: 120.063em)';
// 1921px ~ infinity
$xxlarge-only: '#{$screen} and (min-width: 120.063em) and (max-width: 999999999em)';

// 0 ~ 1024px
$small-medium: '#{$screen} and (max-width: 64em)';

// 0 ~ 1440px
$small-large: '#{$screen} and (max-width: 90em)';
