// For desktop
@media #{$large-up} {
  .order-history-table {
    width: 600px;

    &__header-desktop {
      display: flex;

      span {
        width: 16.5%;
        padding: $spacing-l 0;
        
        &:last-child {
          width: 17.5%;
          text-align: right;
        }
      }
    }

    &__header-mobile {
      display: none;
    }

    &__row {
      &-col {
        display: none;
      }

      &-data {
        span:last-child {
          text-align: right;
        }
      }
    }
  }
}

// For mobile
@media #{$small-medium} {
  .order-history-table {
    width: 100%;

    &__header-desktop {
      display: none;
    }

    &__header-mobile {
      padding: $spacing-l 0;
    }

    &__row {
      display: flex;

      &-col {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        span {
          @include body-small;
        }
      }
      
      &-data {
        flex-grow: 3;
        flex-direction: column;
      }
    }
  }
}

// Common
.order-history-table {
  &__header-desktop, &__header-mobile {
    border-top: 1px solid $line-grey;
    border-bottom: 1px solid $line-grey;
    margin-top: $spacing-xl;

    span {
      @include cta;
      text-transform: uppercase;
    }
  }

  &__row {
    padding: $spacing-l 0;
    border-bottom: 1px solid $line-grey;

    &-data {
      display: flex;

      span {
        @include body-small;
        width: 16.5%;

        &:last-child {
          width: 17.5%;
        }
      }
    }
  }
}
