// For desktop
@media #{$large-up} {
  .product {
    &-hero {
      height: auto;

      &__specs {
        align-items: center !important;

        &-content {
          p {
            width: 470px !important;
          }
          .v65-product-description {
            margin-top: $spacing-xxl-8 !important;
          }

          .cart-action {
            display: flex !important;

            &__purchase {
              display: block !important;
            }
          }

          .purchase-gap {
            margin-top: $spacing-l;
            margin-bottom: $spacing-m;
          }

          &__thumb {
            display: none;
          }
        }
      }

      &__image {
        position: relative;
        display: flex !important;
        // justify-content: center;
        overflow: hidden;

        img {
          height: auto;
        }
      }
    }

    .prod-tasting-notes__thumb,
    .prod-prev-vintages__thumb,
    .prod-wine-award__thumb {
      display: none;
    }
  }

  #sticky-twin-scroll .v65-product-group {
    min-height: 100vw;
  }
}

// For mobile
.product {
  .v65-product-photo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      max-width: 600px;
    }
  }

  .v65-product-attributeValue {
    margin-bottom: 20px;
  }

  .v65-wine-tastingNotes,
  .v65-wine-vineyardNotes,
  .v65-wine-aging {
    .v65-product-attributeLabel {
      display: none;
    }
  }

  .v65-wine-vineyardNotes {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.44;
  }

  .product-hero {
    .v65-product-addToCart-price {
      font-family: "Portrait", "serif";
      font-size: 24px;
      font-weight: 300;
      line-height: 1.44;
      display: flex;
      justify-content: center;
      & > div {
        margin: 0 5px;
      }
      .v65-product-addToCart-originalPrice {
        text-decoration: line-through;
        color: #cbcbcb;
      }
    }
    .v65-product-addToCart-priceWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .v65-addToCartCase {
      padding-top: 20px;
    }
    .v65-product-addToCart-quantity {
      input {
        width: 100% !important;
        border: 1px solid #d6d6d6;
        margin: 10px 0;
        padding: 3px 0 6px;
      }
    }

    .product-hero__image {
      flex-direction: column;
    }

    .multiple-photos {
      bottom: 10px;
      position: absolute;

      .v65-product-photos {
        display: flex;

        .v65-product-photos-photo {
          display: flex;
          align-items: center;
          margin: 10px;
        }
      }
    }
  }

  .smaller {
    font-size: 0.7em;
  }

  .v65-product-addToCart-unitDescription {
    display: none;
  }

  .v65-product-addToCart-button button {
    @include cta;
    width: 250px;
    min-height: 36px;
    text-align: center;
    span {
      color: $white;
      top: 2px;
      position: relative;
    }
    background-color: $green;
    border: none;
    user-select: none;
    cursor: pointer;

    &.out-of-stock {
      background-color: $mid-grey;
    }

    del {
      color: #47a372;
      margin-left: $spacing-xxs;
    }
  }

  &-hero {
    display: flex;

    .v65-product-addToCart {
      legend,
      .v65-product-addToCart-productSKU {
        display: none;
      }
      fieldset {
        border: none;
        padding: 0;
        margin: 0;
      }
    }

    > div {
      width: 100%;
    }

    &__specs {
      display: flex;
      justify-content: center;
      overflow-y: hidden;

      .h1-h2 {
        text-transform: uppercase;
        width: 100% !important;

        .v65-product-teaser {
          p {
            color: #000;
            font-family: "Fleya-VoyagerMono", "serif";
            font-size: inherit;
            font-weight: 300;
            letter-spacing: -2px;
            line-height: 1;
          }
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .v65-product-description {
          margin-top: $spacing-xxl-6;
        }

        p {
          width: 85%;
          margin: 0 auto;
        }

        &__member-price {
          @include body;
          font-style: italic;
          color: $mid-grey;
          margin-top: 10px;
        }

        &__caption-byline {
          @include label;
        }

        &__thumb {
          position: relative;
          margin: $spacing-l 0 $spacing-xxl-3;
          min-height: 400px;

          img {
            width: 70%;
          }
        }

        .cart-action {
          display: none;

          &__purchase {
            display: none;
          }
        }
      }
    }

    &__image {
      display: none;
    }
  }
  .v65-product-addToCart-selectBox {
    select {
      width: 100% !important;
      padding: 0.5rem 0;
      border-radius: 0px;
    }
  }

  .wine-bottle-tag {
    @include cta-white;
    position: absolute;
    top: 50%;
    right: 0;
    height: 24px;
    width: 238px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    background-color: $green;
    transform: rotate(-90deg) translate(12px, calc(107px));
    cursor: url("/assets/images/icons/cursors/cursor_white.svg"), auto;
  }

  .prod-tasting-notes__thumb {
    width: 90%;
    height: 370px;
    background-size: cover;
    background-image: url("/assets/images/category/VE_Category_1_DSC_0198.jpg");
    margin: $spacing-xxl-6 0 $spacing-xxl-4;
  }

  .prod-prev-vintages__thumb {
    width: 90%;
    height: 370px;
    background-size: cover;
    background-image: url("/assets/images/category/VE_Category_1_WinterMenu_Oct1.jpg");
    margin: $spacing-xxl-6 0 $spacing-xxl-4;
  }

  .prod-wine-award__thumb {
    width: 90%;
    height: 370px;
    background-size: cover;
    background-image: url("/assets/images/homepage/desktop/experiences/VE_Homepage_Experiences1_SHOTBYTHOM-0710-2.jpg");
    margin: $spacing-xxl-6 5%;
  }

  .prod-within-range {
    display: none;

    .v65-product-relatedProducts-title,
    .v65-product-reviewStats,
    .v65-clear {
      display: none;
    }

    .v65-addToCart {
      legend,
      .v65-product-addToCart-quantity,
      .v65-product-addToCart-button,
      .v65-product-addToCart-unitDescription,
      .v65-product-addToCart-productSKU {
        display: none;
      }
      .v65-product-addToCart-price {
        &:before {
          display: inline;
          content: "PURCHASE • ";
        }
      }
    }

    .v65-product-relatedProducts {
      display: grid;
      @media #{$medium-up} {
        grid-template-columns: repeat(2, 50%);
      }
      @media #{$large-up} {
        grid-template-columns: repeat(4, 25%);
      }
      grid-template-columns: repeat(1, 100%);
      .v65-product-relatedProducts-product {
        @include h2;
        @media #{$medium-up} {
          padding: 12px 24px;
        }
        .v65-product-teaser {
          display: none;
        }
        .v65-product-title {
          position: relative;
          top: 18px;
          a {
            font-family: "Portrait", "serif";
            font-size: 18px;
            font-weight: 300;
            line-height: 1.44;
            letter-spacing: 0.01em;
          }
        }
        .v65-product-subtitle {
          color: #000;
          position: relative;
          top: -54px;
        }
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        overflow: hidden;
        margin-bottom: $spacing-xxl-3;
        .v65-product-quickView {
          display: none !important;
        }
        .v65-product-photo {
          margin-top: 10px;
          a {
            display: block;
            text-align: center;
          }
        }
        .v65-product-addToCart {
          @include cta-white;
          position: absolute;
          bottom: $spacing-s;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 48px);
          height: 36px;
          text-align: center;
          line-height: 36px;
          text-transform: uppercase;
          background-color: $green;
          opacity: 0;
          transition: $anime-300;
          cursor: pointer;
          fieldset {
            border: none;
            margin: 0;
            padding: 0;
          }
        }
        &:hover {
          .v65-product-addToCart {
            opacity: 1;
          }
        }
        .v65-product-subtitle a {
          @include cta;
          text-transform: uppercase;
          margin-top: 4px;
        }
      }
    }
  }

  .v65-product-media-thumbnail {
    display: none;
  }

  .v65-product-media-title {
    color: #000;
    font-family: "Bond4F", "sans-serif";
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.9px;
    line-height: 0.92;
    position: relative;

    a:after {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      background: url(/assets/images/icons/bow-arrows/bow-arrow_down_black.svg);
      background-repeat: no-repeat;
      transform: rotateZ(-90deg);
      background-position: center;
      background-size: 100%;
      bottom: -2px;
      margin-left: 10px;
    }
  }

  .brand-indicator {
    display: none;
  }
}

#prod-sticky-cart {
  .v65-addToCartCase {
    display: none;
  }
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    legend,
    .v65-product-addToCart-unitDescription,
    .v65-product-addToCart-productSKU {
      display: none;
    }

    .v65-product-addToCart-quantity input {
      border: 1px solid #d6d6d6;
      padding: 3px 0 6px;
      margin: 0px 18px;
    }

    .v65-product-addToCart-button button {
      @include cta;
      padding: 0 20px;
      min-height: 36px;
      text-align: center;
      span {
        color: $white;
        top: 2px;
        position: relative;
      }
      background-color: $green;
      border: none;
      user-select: none;
      cursor: pointer;

      &.out-of-stock {
        background-color: $mid-grey;
      }

      del {
        color: #47a372;
        margin-left: $spacing-xxs;
      }
    }

    .v65-product-addToCart-price {
      font-size: 24px;
      display: flex;
      justify-content: center;
      & > div {
        margin: 0 5px;
      }
      .v65-product-addToCart-originalPrice {
        text-decoration: line-through;
        color: #cbcbcb;
      }
    }
  }
}

.note-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 15px;

  h4 {
    margin: 50px 0px 20px 0px;
  }
}
.prod-notes {
  border-top: 1px solid $line-grey;
  max-width: 300px;
  width: 100%;
}

.prod-notes > div {
  display: flex;
  padding: $spacing-s 0;
  border-bottom: 1px solid $line-grey;

  > span {
    @include cta;
    width: 120px;
    text-transform: uppercase;
  }

  p {
    @include body-small;
    width: calc(100% - 120px);
    margin: 0;
  }
}
