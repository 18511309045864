
.blog-hero {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-position: 0 28%;
  background-size: cover;
}

.blog-hero--disabled {
  display: none;
}

.blog-hero__content {
  max-width: 360px; 
}

.blog-hero__heading,
.blog-hero__subheading {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.blog-hero__heading {
  margin: 0;
  font-family: "Fleya-VoyagerMono","serif";
  font-size: 66px;
  font-weight: 300;
  text-transform: uppercase;
}

.blog-hero__subheading {
  font-family: "Portrait", "serif";
  font-size: 18px;
  font-weight: 300;
}

// For desktop
@media #{$large-up} {
  .blog-hero--bg-image {
    background-image: url('/assets/images/blog/blog-hero--desktop.jpg') !important;
  }

}

// For mobile
.blog-hero--bg-image {
  background-image: url('/assets/images/blog/blog-hero--mobile.jpg');
}

$content-size: 710px;

.blogContent {
  flex-wrap: wrap;
  display: flex;
  max-width: 90%;
  margin: 0 auto;

  h1 {
    position: relative;
    width: 100%;
    text-align: center;
  }

  .v65-socialMediaBar,
  .v65-blogCommentsTitle,
  .v65-commentWrapper {
    display: none;
  }

  .v65-blogPostWrapper {
    width: 100%;
  }

  &.hidden {
    display: none !important;
  }
}

.blogFilterWrapper {
  display: none;
}

.v65-avatar {
  display: none;
}

.v65-blogPost {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: $content-size;
  margin: 0 auto;

  p {
    text-align: justify;
    padding-bottom: 20px;
  }

  h1 {
    font-family: 'Fleya-VoyagerMono';
    font-size: 2rem;
    margin-top: 50px;

    @media screen and (min-width: 570px) {
      font-size: 4rem;
      margin-top: 100px;
    }
  }

  .v65-blogPostDate {
    text-align: center;
    width: 100%;
    font-size: 19px;
    margin-top: 15px;
    font-family: 'Bond4F';
    text-transform: uppercase;
  }
}

.blog-hero-img {
  width: 100%;
  height: auto;
}

.content-section {
  display: block;
  margin-top: 50px;
}

.teaser-section {
  margin-top: 50px;

  img {
    max-width: 100%;
    width: $content-size !important;
    height: 100% !important;
  }
}

.image-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  padding: 0;
  max-width: 980px;

  img {
    display: block;
    float: left;
    flex: 0 0 auto;

    width: 100% !important; // important to override any cms editor inline styles
    height: 100% !important; // important to override any cms editor inline styles
    
    padding-bottom: 8px;

    @media screen and (min-width: 321px) {
      width: calc(100%/2) !important; // important to override any cms editor inline styles
      height: calc(100%/2) !important; // important to override any cms editor inline styles

      &:nth-child(odd) { padding-right: 4px; }
      &:nth-child(even) { padding-left: 4px; } 
    }
  }
}

.blog-list {
  .v65-blogPostWrapper {
    flex-grow: 1;
    padding: 0;
    border: 0;
    margin: 10px;
    height: auto;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;

    .v65-clear {
      display: none;
    }

    @media #{$small-up} {
      width: 100%;
      max-width: 100%;
    }

    @media #{$medium-up} {
      width: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }

    @media #{$large-up} {
      width: calc(33% - 17px);
      max-width: calc(33% - 17px);
    }

    h2 {
      margin: 0;
      padding: 30px 30px 0 30px;

      @media #{$small-up} {
        font-size: 25px;
      }

      @media #{$large-up} {
        font-size: 32px;
      }
  
    }

    .teaser-section {
      display: block;
      width: 100%;
      margin-top: 0;

      .out-section {
        height: 340px;
        overflow: hidden;
        position: relative;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;    
      }
      img {
        min-height: 100% !important;
        height: auto !important;
        width: auto !important;
        max-width: none;
      }
    }

    .content-section {
      display: none;
    }

    .v65-blogPost {
      display: flex;
      flex-direction: column-reverse;

      p {
        text-align: left !important;
        display: none;
      }
    }

    .v65-blogPostDate {
      text-align: left;
      padding: 0px 30px 30px 30px;
    }
  }

  .v65-blogFooter {
    display: none;
  }

  h1 {
    font-family: "Fleya-VoyagerMono", "serif";
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -1.14px;
    line-height: 1;
    text-transform: uppercase;
  }
}

.v65-Paging {
  width: 100%;

  ul {
    padding: 0;
    margin: 0;
    margin: 10px 0 0 10px;
  }

  li {
    display: inline-block;
    margin-right: 25px;
    font-size: 22px;
  }
}

#v65-PagingTop {
  display: none;
}