$minicart-width: 420px;

// For desktop
@media #{$large-up} {
  .active-minicart #minicart-menu {
    width: $minicart-width !important;

    #nav__minicart-mask {
      position: fixed;
      top: 0;
      right: $minicart-width;
      width: calc(100% - #{$minicart-width});
      height: 100%;
      opacity: 0;
    }
  }

  #minicart-menu {
    .nav__menu-content {
      .nav__minicart-sum {
        &_links {
          flex-direction: row-reverse !important;

          a {
            &:first-child {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}

// For mobile
.active-minicart #minicart-menu {
  right: 0;
  
  &::after {
    visibility: visible;
  }
}

#minicart-menu {
  .nav__menu-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $spacing-m $spacing-xs;

    &-empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.hide-empty-cart {
        display: none;
      }
    }

    .nav__close {
      padding: 0 $spacing-xs;

      &-title {
        @include h4;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
      }

      &-subtitle {
        display: flex;
        min-height: 12px;
        
        .item-added__tick {
          width: 18px;
          height: 18px;
          background: $black url('/assets/images/icons/symbols/tick_white.svg') no-repeat center;
          border-radius: 50%;
          margin-right: 7px;
        }
        
        p {
          @include body-small;
          font-style: italic;
          width: calc(100% - 18px - 7px);
          height: $spacing-xxl-4;
          margin: 0;
          overflow: hidden;
        }
      }
    }

    .nav__minicart {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;

      .cart-item {
        display: flex;
        padding: $spacing-m 0;
        border-bottom: 1px solid $line-grey;

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        
        &__thumb {
          img {
            width: $spacing-xxl-6;
          }
        }

        &__details {
          display: flex;
          justify-content: space-between;
          width: calc(100% - #{$spacing-xxl-6});
          
          &__specs {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-right: $spacing-xs;

            > div {
              margin-right: $spacing-xs;
            }
            
            &-year {
              @include body-small;
            }
            
            &-name {
              @include body-small;
            }

            &-promo {
              @include body-small;
              font-style: italic;
            }
          }

          &__price {
            @include body-small;
            margin-right: $spacing-xs;

            span {
              @include body-small;
              display: block;
            }
          }
        }
      }
    }
    
    .nav__minicart-sum {
      background-color: $cream-tint;
      padding-top: $spacing-m;
      border-top: 1px solid $line-grey;

      &_subtotal {
        @include h4;
        display: block;
        width: 100%;
        padding: 0 $spacing-xs;
      }
      
      &_subtitle {
        @include body-small;
        display: block;
        width: 100%;
        font-style: italic;
        padding: 0 $spacing-xs;
        height: $spacing-xxl-3;
        margin-bottom: $spacing-xs;
      }

      &_links {
        display: flex;
        flex-direction: column;

        a {
          display: block;
          width: 100%;
          height: 36px;
          line-height: 36px !important;
          text-align: center !important;
          
          &:first-child {
            @include cta-white;
            background-color: $black;
            text-transform: uppercase;
            margin-bottom: $spacing-xs;
          }
          
          &:last-child {
            @include cta;
            border: 1px solid $line-grey;
            text-transform: uppercase;
            margin-right: $spacing-xs;
          }
        }
      }
    }
  }
}
