// For desktop
@media #{$large-up} {
  .our-story__hero {
    background-image: url('/assets/images/our_story/desktop/VE_Our Story_2-11-20_0214.jpg') !important;
  }

  .sideNavLayout-content {
    .our-story__body {
      padding-top: 0 !important;
    }
  
    .our-story__h2-top-gap-desktop {
      padding-top: 180px;
      padding-bottom: 100px;
      margin-top: 10px !important;
    }

    .our-story__h2-top-gap-desktop-small {
      padding-top: 75px;
      margin-top: 10px !important;
    }
  
    .our-story__h2 {
      width: 650px !important;
    }

    .our-story__figure-no-bottom-gap {
      margin-bottom: 0 !important;
    }
  }

  .our-story__h2-top-gap {
    padding-top: 180px !important;
    padding-bottom: 100px;
  }
}

// For mobile
.our-story__hero {
  background-image: url('/assets/images/our_story/mobile/VE_Our Story_2-11-20_0214.jpg');
}

.our-story__video {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: $spacing-xxl-6;

  video {
    width: 100%;
  }
  
  &-caption {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 5%;
    background-image: url('/assets/images/our_story/desktop/our-story_video_poster.jpg');
    background-size: cover;
    transition: $anime-500;
    cursor: pointer;

    &__hide {
      opacity: 0;
      visibility: hidden;
    }

    h3 {
      @include h3-caps-white;
      width: 300px;
      margin-bottom: $spacing-l;
    }

    &__watch {
      @include body-small-white;
      display: flex;
      align-items: center;

      &-button {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $white url('/assets/images/icons/symbols/triangle_arrow_right-black.svg') no-repeat 8px center;
        background-size: 40%;
        margin-right: $spacing-s;
      }
    }
  }
}

.our-story__h2-top-gap {
  padding-top: 75px;
  margin-top: 10px !important;
}

.inline-link {
  text-decoration: underline;
}