// For desktop
@media #{$large-up} {
  .restaurant__cta {
    top: 50%;
    bottom: unset;
    left: 0;
    width: 232px !important;
    transform: rotate(-90deg) translateY(calc(-50% - 68px));
    -webkit-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 2px 0px 5px 0px rgba(0,0,0,0.2);
  }

  .restaurant__hero {
    &-caption {
      max-width: 640px;
      font-size: 66px !important;
      letter-spacing: -2.5px !important;
      margin: 0;
    }
  }

  .restaurant__making-caption {
    h3 {
      width: 600px !important;
    }
  
    h4 {
      width: 540px !important;
    }
  }

  .restaurant__making {
    height: calc(100vh - 115px * 2) !important;
    margin-left: 140px !important;
    margin-right: 140px !important;
  }

  .restaurant__menus {
    flex-direction: row !important;
    padding-right: 0 !important;
    padding-bottom: 200px !important;
    padding-left: 0 !important;
    margin-right: $spacing-xl;
    margin-left: $spacing-xl;

    > div {
      width: 25%;
      margin-right: $spacing-xxl-3;

      &:last-child {
        margin-right: 0;
      }

      h2 {
        margin: 0 0 $spacing-xl !important;
      }
      
      h4 {
        margin: $spacing-xs 0 $spacing-m !important;
      }

      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .restaurant__chef {
    flex-direction: row !important;
    margin-right: $spacing-xl;
    margin-left: $spacing-xl;

    > div {
      width: 50%;
    }

    &-caption {
      h4 {
        width: 250px;
      }

      div {
        width: 410px !important;
      }
    }
  }

  .restaurant__article {
    flex-direction: row !important;
    margin-right: $spacing-xl;
    margin-left: $spacing-xl;
    margin-bottom: $spacing-xl;

    > div {
      width: 50%;
    }

    h3 {
      width: 410px !important;
    }
  }
}

// For mobile
.restaurant__cta {
  @include cta-white;
  position: fixed;
  z-index: 200;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  text-transform: uppercase;
  background-color: $green;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.2);
  cursor: pointer;
}

.restaurant__hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center;

  &-caption {
    @include h2-white;
    width: 85%;
    text-transform: uppercase;
  }
}

.restaurant__making-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 120px 0;

  h3, h4 {
    width: 80%;
    margin: 0;
  }
  
  h3 {
    @include h3-caps;
    text-transform: uppercase;
    margin-bottom: $spacing-xl;
  }

  h4 {
    @include body;
  }
}

.restaurant__making {
  height: 376px;
  background-size: cover;
  background-position: center;
  margin: 0 $spacing-xl 115px;
}

.restaurant__menus {
  border-top: 1px solid $line-grey;
  display: flex;
  flex-direction: column;
  padding: $spacing-xl $spacing-xl $spacing-xxl-6;


  > div {
    &:first-child {
      padding-bottom: $spacing-xxl-6;
    }

    > div {
      background-size: cover;
      background-position: center;
      margin-bottom: $spacing-xl;
    }

    .card-size-xl {
      padding-bottom: 133.33%;
    }
    .card-size-l {
      padding-bottom: 123.53%;
    }
    .card-size-m {
      padding-bottom: 92.16%;
    }
    .card-size-s {
      padding-bottom: 72.55%;
    }

    a {
      @include cta;
      text-transform: uppercase;
      padding-right: $spacing-l;
      background: transparent url('/assets/images/icons/bow-arrows/bow-arrow_right_black.svg') no-repeat right center;
      cursor: pointer;
    }
    
    h2 {
      text-transform: uppercase;
      margin: 0;
    }
    
    h4 {
      text-transform: uppercase;
      margin: $spacing-xs 0 0;
    }

    p {
      @include body-small;
      margin-bottom: $spacing-xxl-3;
    }
  }
}

.restaurant__chef {
  display: flex;
  flex-direction: column-reverse;

  &-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;

    h4 {
      width: 70%;
      text-transform: uppercase;
    }

    div {
      @include body;
      width: 80%;
    }
  }

  &-image {
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
}

.restaurant__article {
  display: flex;
  flex-direction: column;
  background-color: $green;

  &-image {
    height: 100vh;
    background-size: cover;
    background-position: center;
  }

  &-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    cursor: url('/assets/images/icons/cursors/cursor_white.svg'), auto;

    span {
      @include cta-white;
      text-transform: uppercase;
    }

    h3 {
      @include h3-caps-white;
      width: 90%;
      text-transform: uppercase;
    }
  }
}
