// For desktop
@media #{$large-up} {
  .winemaking-hero {
    background-image: url('/assets/images/winemaking/desktop/winemaking-desktop-hero.jpg') !important;
  }

}

// For mobile
.winemaking-hero {
  background-image: url('/assets/images/winemaking/mobile/winemaking-mobile-hero.jpg');
}

.switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .then {
    font-family: Bond4F;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #b8bdb7
  }
  .dot {
    width: 6px;
    height: 6px;
    margin: 0 13px 2px 13px;
    background-color: black;
    border-radius: 50%;
  }
  .new {
    font-family: Bond4F;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #b8bdb7;
  }

  .toggle-active {
    color: black;
  }

  .content1 {
    display: none;
  }
}
