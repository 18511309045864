// For desktop
@media #{$large-up} {
  .garden__hero {
    background-image: url('/assets/images/garden_philosophy/desktop/VE_Garden_2-11-20_0872.jpg') !important;

    &-caption {
      max-width: 1120px;
      font-size: 66px !important;
      letter-spacing: -2.5px !important;
    }
  }

  .garden__from {
    h2 {
      width: revert !important;
    }
    p {
      width: 470px !important;
    }
  }

  .garden__plate {
    height: calc(100vh - 115px * 2) !important;
    margin-left: 140px !important;
    margin-right: 140px !important;
  }

  .garden__landscape {
    h3 {
      width: 480px !important;
      margin: 0 !important;
    }
  }

  .garden__photo {
    display: flex;
    height: 100vh;

    > div {
      width: 50% !important;
    }

    &-image {
      img {
        width: 330px !important;
      }

      figcaption {
        @include body-small;
        margin-top: $spacing-s;
      }
    }

    &-caption {
      > div {
        width: 370px !important;
      }

      p {
        margin: 0 !important;
      }
    }
  }
}

// For mobile
.garden__hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: url('/assets/images/garden_philosophy/mobile/VE_Garden_2-11-20_0872.jpg');
  background-size: cover;
  background-position: center;

  &-caption {
    @include h2-white;
    width: 85%;
    text-transform: uppercase;
    margin: 0;
  }
}

.garden__from {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 120px 0;

  h2, p {
    margin: 0;
  }
  
  h2 {
    width: 200px;
    text-transform: uppercase;
    margin-bottom: $spacing-xl;
  }
  
  p {
    @include body;
    width: 80%;
  }

  .cta-button-black {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.garden__plate {
  height: 376px;
  background-image: url('/assets/images/garden_philosophy/desktop/VE_Garden_DSC_0182.jpg');
  background-size: cover;
  background-position: center;
  margin: 0 $spacing-xl 115px;
}

.garden__landscape {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  h3 {
    @include h3-caps;
    width: 80%;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 115px;
  }
}

.garden__photo {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-image {
    figure {
      margin: 0 $spacing-xl;

      img {
        width: 100%;
      }
    }
  }

  &-caption {
    > div {
      width: 100%;

      p {
        margin: $spacing-xxl-6 $spacing-xl $spacing-xxl-8;
      }
    }
  }
}
