// For desktop
@media #{$large-up} {
  .closure {
    &-panel {
      width: 620px !important;

      h1 {
        width: 100%;
      }
      
      p {
        width: 465px;
      }
    }
  }
}

// For mobile
.closure {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('/assets/images/garden_philosophy/desktop/VE_Garden_2-11-20_0872.jpg');
  background-size: cover;
  background-position: center;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);

  &-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 374px;
    background-color: $white;
    
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h1 {
        @include h3-caps;
        width: 80%;
        margin: 0;
      }
      
      p {
        @include body;
        width: 90%;
        margin: $spacing-m 0 0;
      }
    }
  }
}
