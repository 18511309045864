// For desktop
@media #{$large-up} {
  h1 {
    margin-top: 0;
    margin-bottom: $spacing-xxl-7;
  }

  .dist__country {
    &-col {
      width: 80%;
    }
  }
}

// For mobile
@media #{$small-medium} {
  h1 {
    margin-top: $spacing-xxl-3;
    margin-bottom: $spacing-xxl-5;
  }

  .dist__country {
    > div {
      flex-direction: column;
    }
  }
}

// Common
.dist__country {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '.'
    '.';

  &:first-of-type .dist__country-group-heading {
    margin-top: 10%;
  }

  &-group-heading {
    grid-column-start: 1;
    grid-column-end: 3;
    height: auto;
    margin-top: 5%;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.9px;
    line-height: 0.92;
    text-transform: uppercase;
    font-family: $portrait;
  }

  &-item {
    margin-bottom: 1.75em;
    * {
      margin-top: 0;
      font-size: 15.5px;
    }
  }
  &-item-location {
    font-family: $bond4f;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    line-height: 1.2;
  }
  &-item-location,
  &-item-name,
  &-item-phone {
    margin-bottom: 3px;
  }
  &-item-website {
    span {
      margin-bottom: 0.3em;
      font-size: 18px;
      font-weight: 500;
    }
  }

  span {
    display: block;
  }

  &-col {
    &-name {
      margin-top: $spacing-xl;
    }
  }
}
