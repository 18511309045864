// For desktop
@media #{$large-up} {
  .wine-club-sigeup {
    width: fit-content !important;

    &__wrap {
      &-form {
        border-top: 1px solid $line-grey;

        .form-column-row {
          &__col {
            margin-right: $spacing-s;
    
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &-type {
        width: 300px;
        margin-left: $spacing-xxl-8;
      }
    }
  }
}

// For mobile
@media #{$small-medium} {
  .wine-club-sigeup {
    margin-top: $spacing-xxl-3;

    &__title {
      padding-bottom: $spacing-m;
      border-bottom: 1px solid $line-grey;
    }

    &__wrap {
      flex-direction: column-reverse;

      &-form {
        .form-wrap {
          margin-top: $spacing-m;
        }

        .form-column-row {
          flex-direction: column;

          &__col-no-label {
            margin-bottom: $spacing-m;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

// Common
.wine-club-sigeup {
  &__title {
    h1 {
      text-transform: uppercase;
      margin: 0 0 $spacing-xxs;
    }
    span {
      @include body-small;
    }
  }
  
  &__wrap {
    display: flex;
    margin-top: $spacing-m;
  
    &-form {
      @include form(694px, 100%, $line-grey, $black);
      padding-top: $spacing-xxl;
  
      h4 {
        text-transform: uppercase;
        margin: 0 0 $spacing-xxs;
      }
      span {
        @include body-small;
      }

      .form-wrap {
        // input {
        //   background-color: $white;
        // }
    
        .add-email-address {
          border-color: $black;
        }

        .form-column-row {
          display: flex;
      
          &__col {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }
        }

        .update-info {
          color: $white;
          background-color: $black;
          border: none;
        }
    
        .dropdown-states {
          @include body-small;
          margin: 0;
          height: 42px;

          input, ul li, select, option {
            text-transform: capitalize;
          }
        }
    
        // .checkbox-tick-box {
        //   background-color: $white;
        // }
      }

      > p {
        border-bottom: 1px solid $line-grey;
        padding: $spacing-s 0;
        margin: 0;
        
        &:first-of-type {
          border-top: 1px solid $line-grey;
          margin-top: $spacing-xxl-3;
        }
      }
    }
  
    &-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      background-color: $black;
      padding: 0 $spacing-xl $spacing-xl;
      border: 1px solid $line-grey;
  
      h2 {
        color: $white;
        text-transform: uppercase;
        margin: $spacing-xxl-2 0 0;
      }
  
      p {
        @include body-small;
        width: 245px;
        text-align: center;
        color: $white;
        margin: $spacing-l 0 $spacing-xxl-2;
      }

      div {
        color: $white;
      }
    }
  }
}
