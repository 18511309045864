// For desktop
@media #{$large-up} {
  .experiences__hero {
    background-image: url('/assets/images/experiences/desktop/experiences-deskop-hero.jpg') !important;

    &-caption {
      max-width: 1070px;
      font-size: 66px !important;
      letter-spacing: -2.5px !important;
    }
  }

  .experiences__tastings {
    flex-direction: row !important;
    margin: $spacing-xl;

    > div {
      width: 50%;
    }

    &-caption {
      h4 {
        width: 250px;
      }

      div {
        width: 337px !important;
      }
    }

    &-image {
      margin: 0 !important;
    }
  }

  .experiences__menus {
    flex-direction: row !important;
    padding-right: 0 !important;
    padding-bottom: 200px !important;
    padding-left: 0 !important;
    margin-right: $spacing-xl;
    margin-left: $spacing-xl;

    > div {
      &:last-child {
        margin-right: 0;
      }

      h2 {
        margin: 0 0 $spacing-xl !important;
      }

      h4 {
        margin: $spacing-xs 0 $spacing-m !important;
      }

      p {
        margin: 0 !important;
      }
    }
  }

  .experiences__article {
    flex-direction: row !important;
    margin-right: $spacing-xl;
    margin-left: $spacing-xl;
    margin-bottom: $spacing-xl;

    > div {
      width: 50%;
    }

    h3 {
      width: 410px !important;
    }
  }
}

// For mobile
.experiences__hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: url('/assets/images/experiences/mobile/experiences-mobile-hero.jpg');
  background-size: cover;
  background-position: center;

  &-caption {
    @include h2-white;
    width: 85%;
    text-transform: uppercase;
    margin: 0;
  }
}

.experiences__tastings {
  display: flex;
  flex-direction: column-reverse;

  &-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;

    h4 {
      width: 75%;
      text-transform: uppercase;
    }

    div {
      @include body-small;
      width: 75%;
    }
  }

  &-image {
    height: 100vh;
    background-image: url('/assets/images/experiences/desktop/VE_Experiences_0067-2.jpg');
    background-size: cover;
    background-position: center;
    margin: 24px 24px 0;
  }
}

.experiences__menus {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 1rem;
  padding: $spacing-xl $spacing-xl $spacing-xxl-6;
  border-top: 1px solid $line-grey;

  > div {
    &:first-child {
      padding-bottom: $spacing-xxl-6;
    }

    > div {
      background-size: cover;
      background-position: center;
      margin-bottom: $spacing-xl;
    }

    .experiences__menus-image-1 {
      background-image: url('/assets/images/experiences/desktop/VE_Experiences_SHOTBYTHOM-9579.jpg');
    }
    .experiences__menus-image-2 {
      background-image: url('/assets/images/experiences/desktop/VE_Experiences_SHOTBYTHOM-0038.jpg');
    }
    .experiences__menus-image-3 {
      background-image: url('/assets/images/experiences/desktop/VE_Experiences_SHOTBYTHOM-9759.jpg');
    }
    .experiences__menus-image-4 {
      background-image: url('/assets/images/experiences/desktop/VE_Experiences_Events images M_SHOTBYTHOM-9436.jpg');
    }
    .experiences__menus-image-5 {
      background-image: url('/assets/images/experiences/desktop/VE_Experiences_Events images L_2-11-20_0880.jpg');
    }
    .experiences__menus-image-6 {
      background-image: url('/assets/images/experiences/desktop/VE_Experiences_Events images M_VoyagerEstate_WinterMenu_Oct1.jpg');
    }

    .experiences__menus-date {
      @include cta;
      text-transform: uppercase;
    }

    .experiences__menus-even-margins {
      margin: $spacing-xs 0 !important;
    }

    .experiences__menus-price {
      display: block;
      width: fit-content;
      padding-right: $spacing-m;
      background-image: url('/assets/images/icons/spear-arrows/spear-arrow_right_black.svg') !important;
      margin-bottom: $spacing-l;
    }

    button {
      padding-top: $spacing-xxs;
    }

    button,
    a {
      @include cta;
      text-transform: uppercase;
      padding-right: $spacing-l;
      padding-left: 0;
      background: transparent
        url('/assets/images/icons/bow-arrows/bow-arrow_right_black.svg')
        no-repeat right center;
      cursor: pointer;
    }

    h2 {
      text-transform: uppercase;
      margin: 0;
    }

    h4 {
      text-transform: uppercase;
      margin: $spacing-xs 0 0;
    }

    p {
      @include body-small;
      margin-bottom: $spacing-xxl-3;
    }
  }
}
