// For desktop
@media #{$large-up} {
  .gift-card-section {
    .slides-twin-scroll {
      .sts-scroll {
        .gift-card__mobile-hero {
          display: none !important;
        }

        > section {
          flex-direction: column !important;

          > p {
            width: 470px !important;
          }
        }
      }
    }
  }
}

// For mobile
.gift-card-section {
  border: none !important;

  .slides-twin-scroll {
    .sts-scroll {
      .gift-card__mobile-hero {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-size: cover;
      }

      > section {
        flex-direction: column !important;

        .h1-h2 {
          margin: 0 0 48px;
        }
      
        .dropdown-voucher {
          width: 250px;
          height: 36px;
          margin-bottom: $spacing-m;

          input, ul li, option {
            text-transform: uppercase;
          }
        }
      
        > p {
          @include body;
          width: 85%;
          text-align: center;
          margin: $spacing-xxl-8 0 0;
        }
      
        .gift-card-instructions {
          width: 318px;

          &:last-child {
            margin-top: $spacing-xxl-5;
          }
      
          h4 {
            text-transform: uppercase;
            border-bottom: 1px solid $line-grey;
            padding: 0 0 $spacing-s;
            margin: 0;
          }
          
          p {
            @include body-small;
            border-bottom: 1px solid $line-grey;
            padding: $spacing-s 0;
            margin: 0;
          }
        }
      }
    }
  }
}
