.cart__wrap {
  .cart {
    padding-bottom: 40px;
    .v65-clear {
      display: none;
    }



    .nav-placeholder {
      height: auto !important;
    }

    fieldset {
      border: none;
      margin: 0;
      padding: 0;
    }

    tr {
      th {
        text-align: left;
      }
    }

    .v65-cartItemAmount {
      fieldset {
        display: flex;
        width: 120px;
        input {
          width: 100%;
          flex: 1;
          height: 40px;
          padding: 0 8px;
          background-color: transparent;
          border: 1px solid #d6d6d6;
        }
        button {
          flex: none;
          height: 40px;
          line-height: 40px;
          width: 75px;
          text-align: center;
          color: #fff;
          background-color: #000;
        }
      }
    }

    #v65-cartHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding: 30px 0;
    }

    #v65-cartCheckOutCoupons {
      div {
        display: flex;
      }
      input {
        width: 100%;
        flex: 1;
        height: 40px;
        padding: 0 8px;
        background-color: transparent;
        border: 1px solid #d6d6d6;
      }
      button {
        flex: none;
        height: 40px;
        line-height: 40px;
        width: 75px;
        text-align: center;
        color: #fff;
        background-color: #000;
      }
    }
    .modalBtn, .linkAltBtn, .largeLinkBtn {
      margin-left: 15px;
      @include cta-white;
      span {
        @include cta-white;
      }
      display: block;
      height: 40px;
      text-align: center;
      text-transform: uppercase;
      line-height: 40px;
      padding: 0 20px;
      background-color: $black;
      cursor: pointer;
    }
    .v65-cartCheckOutButtons {
      display: flex;
      justify-content: flex-end;
    }

  }

  .v65-cartItemDescription-sku {
    display: none;
  }
}

// For desktop
@media #{$large-up} {
  .cart__wrap {
    .cart {

      &__body {
        flex-direction: row !important;

        &-content {
          width: calc(100% - 300px - #{$spacing-m}) !important;
          margin-right: $spacing-m;

          &__item-list {
            &__header {
              @include cta;
              display: flex !important;
              text-transform: uppercase;
              border-bottom: 1px solid $line-grey;
              padding-bottom: $spacing-xxs;

              &-items {
                width: 56%;
              }
              &-price {
                display: flex;
                align-items: center;
                width: 11%;
              }
              &-qty {
                width: 13%;
              }
              &-total {
                width: 20%;
              }
            }

            &__entry {
              &-thumb {
                width: 10% !important;
              }

              .entry-group__npq {
                display: flex;
                width: 70% !important;

                &-name {
                  width: 65% !important;
                }
                &-price {
                  display: flex !important;
                  align-items: center;
                  width: 16%;
                }
                &-qty {
                  flex-direction: column;
                  justify-content: center;
                  width: 19% !important;
                  margin: 0;

                  button {
                    width: $spacing-xxl-4;
                    margin: $spacing-xxs 0 0 !important;
                  }
                }
              }
  
              .entry-group__tr {
                flex-direction: row !important;
                justify-content: unset !important;
                align-items: center !important;
                width: 20% !important;

                &-total {
                  display: flex !important;
                  flex-direction: column !important;
                  justify-content: center !important;
                  width: 70% !important;

                  span {
                    margin: 0 !important;
                  }
                }
                &-remove {
                  width: 30% !important;
                }
              }
            }
          }

          &__notes {
            textarea {
              width: 330px !important;
              min-width: 330px !important;
              max-width: 500px !important;
            }
          }
        }

        &-summary {
          width: 300px !important;
          margin: 0 !important;
        }
      }
    }
  }  
}

// For mobile
.cart__wrap {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-top: $spacing-xxl-3;
  
  .cart {
    width: 90%;
    max-width: 1124px;

    &__title {
      h1 {
        @include h4;
        margin: 0 0 $spacing-xxs;
      }

      span {
        @include body-small;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      margin-top: $spacing-xxl-3;
      
      &-content {
        width: 100%;

        &__item-list {
          &__header {
            display: none;
          }
          
          &__entry {
            @include body;
            display: flex;
            padding: $spacing-m 0;
            border-bottom: 1px solid $line-grey;

            &-thumb {
              width: 20%;

              img {
                width: 100%;
              }
            }

            .entry-group__npq {
              width: 50%;

              &-name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 90%;

                &__promo {
                  font-style: italic;
                }
              }
              &-price {
                display: none;
              }
              &-qty {
                display: flex;
                width: 90%;
                margin-top: $spacing-l;

                input {
                  @include cta;
                  width: $spacing-xxl-4;
                  height: $spacing-xxl-2;
                  text-align: center;
                  background-color: transparent;
                  border: 1px solid $black;
                }
                button {
                  @include body-small;
                  text-decoration: underline;
                  background-color: transparent;
                  padding: 0;
                  margin-left: $spacing-xs;
                }
              }
            }

            .entry-group__tr {
              display: flex;
              flex-direction: column-reverse;
              justify-content: space-between;
              align-items: flex-end;
              width: 30%;

              &-total {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                span:last-child {
                  margin-left: $spacing-xs;
                }
              }
              &-remove {
                width: 24px;
                height: 24px;
                background: url('/assets/images/icons/symbols/close_grey.svg') no-repeat center;
                cursor: pointer;
              }
            }
          }
        }
        
        &__notes {
          display: flex;
          flex-direction: column;
          
          span {
            @include cta;
            text-transform: uppercase;
            margin: $spacing-m 0 $spacing-s;
          }

          textarea {
            @include body-small;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            min-height: 118px;
            max-height: 400px;
            padding: $spacing-xs;
            background-color: transparent;
            border: 1px solid $line-grey;
          }
        }
      }
      
      &-summary {
        width: 100%;
        margin-top: $spacing-xxl;

        > span, button {
          @include cta;
          text-transform: uppercase;
        }

        button {
          height: 40px;
          width: 100%;
          text-align: center;
          color: $white;
          background-color: $black;
        }

        .cart-checkout-row {
          display: flex;
          justify-content: space-between;
          margin-top: $spacing-s;
          margin-bottom: $spacing-l;

          > span {
            @include cta;
            text-transform: uppercase;
          }
        }

        &__coupon {
          margin-bottom: $spacing-xl !important;

          input {
            width: calc(100% - 79px);
            height: 40px;
            padding: 0 $spacing-xs;
            background-color: transparent;
            border: 1px solid $line-grey;
          }
          button {
            height: 40px;
            width: 75px;
            text-align: center;
            color: $white;
            background-color: $black;
          }
        }

        .dropdown-states {
          width: 100%;
          height: 40px;

          input, ul li, option {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .dropdown {
    .dropdown-mobile-arrow {
      height: 40px;
    }
  }
}
