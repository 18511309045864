.footer {
  width: 100%;
  background-color: $dark-grey;
  padding: $spacing-xl;
  cursor: url('/assets/images/icons/cursors/cursor_white.svg'), auto;
  
  @media #{$xlarge-up} {
    min-height: unset;
    // height: 86px;
    padding: $spacing-l;

    &__sublinks {
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    }
  }
  
  .footer-wrap {
    height: 100%;
    position: relative;

    @media #{$xlarge-up} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__links, &__sublinks {
    list-style-type: none;
    padding: 0 0 $spacing-xxl;
    margin: 0;
  }

  &__links {
    @media #{$xlarge-up} {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
    }
  }

  &__sublinks {
    overflow: hidden;
    max-height: 0;
    padding: 0;
    transition: $anime-500;

    @media #{$xlarge-up} {
      position: absolute;
      bottom: $spacing-xxl;
      background-color: $white;
    }

    .active-menu & {
      max-height: 100vh;
    }

    .footer__link-text {
      padding: $spacing-m $spacing-xl $spacing-s;

      @media #{$xlarge-up} {
        @include cta;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $black;
        text-align: right;
        padding: $spacing-m $spacing-m $spacing-xs $spacing-xxl-2;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__link {
    position: relative;

    &-text {
      @include body-small-white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-transform: uppercase;
      padding: $spacing-m 0 $spacing-s;
      border-bottom: 1px solid $dark-mid-grey;

      @media #{$xlarge-up} {
        line-height: .86;
        letter-spacing: .5px;
        padding: 0 $spacing-xxl-2 0 0;
        border-bottom-color: transparent;
      }
    }

    &-arrow {
      position: relative;
      top: -2px;
      left: 7px;
      transition: $anime-500;

      .active-menu & {
        transform: rotate(-180deg);
      }
    }
  }

  &__copyright {

    width: auto;
    bottom: 0px;

    @media #{$xlarge-up} {
      width: auto;
      position: relative;
    }

    &-text {
      @include body-small-white;
      font-size: 12px;
    }

    a {
      color: $white;

      @media #{$xlarge-up} {
        display: inline-block;
        margin-left: $spacing-s;

        &::before {
          content: '|';
          display: inline-block;
          padding-right: $spacing-s;
        }
      }
    }
  }

  &-ack {
    color: $white;
    font-size: $spacing-s;
    padding-top: $spacing-xxs;
    padding-bottom: $spacing-xs;

    @media #{$small-large} {
      padding-bottom: 0;
      margin-top: $spacing-xxl-2;
      margin-bottom: $spacing-m;
    }
  }
}