// For desktop
@media #{$large-up} {
  .section-one-packages {
    flex-direction: row !important;
    padding: $spacing-xxl-8 0 !important;

    > div {
      width: 30%;
      margin-right: 5%;

      &:last-child {
        margin-right: 0;
      }

      h2 {
        margin: 0 0 $spacing-xl !important;
      }
      
      h4 {
        margin: $spacing-xs 0 $spacing-m !important;
      }

      p {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }

  .top-margin {
    margin-top: 0 !important;
  }
}

// For mobile
.section-one-packages {
  display: flex;
  flex-direction: column;
  padding: $spacing-xxl-6 0 0;

  > div {
    > div {
      background-size: cover;
      background-position: center;
      margin-bottom: $spacing-xl;
    }

    .wedding-packages-image-1 {
      background-image: url('/assets/images/wedding/desktop/VE_Wedding_GinPig_16.jpg');
    }
    .wedding-packages-image-2 {
      background-image: url('/assets/images/wedding/desktop/VE_Wedding_SHOTBYTHOM-1943.jpg');
    }
    .wedding-packages-image-3 {
      background-image: url('/assets/images/wedding/desktop/VE_Wedding_VoyagerEstate_SummerMenu_Marron1.jpg');
    }

    .wedding-packages-date {
      @include cta;
      text-transform: uppercase;
    }

    .wedding-packages-even-margins {
      margin: $spacing-xs 0 !important;
    }

    .wedding-packages-price {
      display: block;
      width: fit-content;
      padding-right: $spacing-m;
      background-image: url('/assets/images/icons/spear-arrows/spear-arrow_right_black.svg') !important;
      margin-bottom: $spacing-l;
    }

    button, a {
      @include cta;
      text-transform: uppercase;
      padding-right: $spacing-l;
      padding-left: 0;
      background: transparent url('/assets/images/icons/bow-arrows/bow-arrow_right_black.svg') no-repeat right center;
      cursor: pointer;
      line-height: 1;
    }
    
    h2 {
      text-transform: uppercase;
      margin: 0;
    }
    
    h4 {
      text-transform: uppercase;
      margin: $spacing-xs 0 0;
    }

    p {
      @include body-small;

      &:last-child {
        margin-bottom: $spacing-xxl-3;
      }
    }
  }
}

.top-margin {
  margin-top: $spacing-xxl-6;
}
