// For desktop
@media #{$large-up} {
  .shipping-delivery__title {
    margin-top: 0;
    margin-bottom: $spacing-xxl-7;
  }
}

// For mobile
@media #{$small-medium} {
  .shipping-delivery__title {
    margin-top: $spacing-xxl-3;
    margin-bottom: $spacing-xxl-5;
  }
}

.shipping-delivery__table-title {
  display: block;
  text-transform: uppercase;
  margin-bottom: $spacing-m;
}

.shipping-delivery__table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: $spacing-xxl-2;

  td {
    padding: $spacing-xs;
    border: 1px solid $line-grey;
  }
}
