$height: 1400px;

// For mobile
@media #{$small-medium} {
  .sign-up-form {
    .form-wrap {
      p {
        width: 80% !important;
      }
    }
  }
}

// Newsletter Sign up Form ID
#Form61A02438-9CA8-477D-1C30-D9ADFBAFE6BC {
  .v65-customForm {
    fieldset {
      margin: 0 auto;
    }
  }
}

// Common
.sign-up-form {
  @include form(300px, 80%, $white, $white);

  .form-wrap {
    @include min-viewport-height;
    position: absolute;
    height: $height;

    h1 {
      margin: 0;
    }
  }

  .form-bg {
    @include min-viewport-height;
    height: $height;
    background: url('/assets/images/homepage/desktop/hero_banner/VE_Homepage_4.jpg')
      no-repeat center;
  }

  .form-mask {
    @include min-viewport-height;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $height;
    background-color: $black;
    opacity: 0.6;
  }

  .dropdown-sign-up {
    margin: 0;
    height: 42px;
    background-color: transparent;

    input,
    ul li,
    option {
      text-transform: uppercase;
    }

    .dropdown-current {
      background-color: transparent;

      input {
        color: $white;
        background-color: transparent;
      }

      &__arrow {
        background-image: url('/assets/images/icons/spear-arrows/spear-arrow_down_white.svg');
      }
    }

    select {
      color: $white;

      option {
        color: $black;
      }
    }

    .dropdown-mobile-arrow {
      height: 42px;
      background-image: url('/assets/images/icons/spear-arrows/spear-arrow_down_white.svg');
    }
  }
}
