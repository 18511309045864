$nav-menu-width: 400px;

// For desktop
@media #{$large-up} {
  .nav {
    &__menu {
      width: $nav-menu-width !important;
    }

    #options-menu::after {
      content: "";
      position: fixed;
      top: 0;
      left: $nav-menu-width;
      width: calc(100% - #{$nav-menu-width});
      height: 100%;
      opacity: 0;
      visibility: hidden;
    }

    &__links {
      height: revert !important;
      margin-top: 0 !important;

      &:hover {
        a {
          color: $mid-grey;
        }
      }
    }

    &__link-text:hover {
      color: $black !important;
    }
  }

  .active-search #search-menu {
    #nav__search-mask {
      position: fixed;
      top: 0;
      right: 360px;
      width: calc(100% - 360px);
      height: 100%;
      opacity: 0;
    }
  }
}

// For mobile
.nav {
  &__menu {
    position: fixed;
    z-index: 500;
    top: 0;
    width: calc(100% - #{$spacing-xl});
    height: 100%;
    background-color: $cream-tint;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    transition: all 0.4s;

    &-content {
      padding: $spacing-xl;
      width: 100%;
    }

    &-overhead {
      padding-top: 97px;
    }

    &-left {
      left: -100%;
    }

    &-right {
      right: -100%;
    }

    .menu-search-content {
      height: 100%;
    }

    .mobile-search-content {
      height: 100%;
    }
  }

  &__links,
  &__sublinks {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__links {
    height: calc(100% - 160px);
    overflow: auto;
    width: 100%;
    margin-top: $spacing-xxl-2;
    transition: all 0.2s ease-in-out 0.4s;

    .search-results-available & {
      opacity: 0;
      visibility: hidden;
    }

    &.active-child-menu {
      .nav__link:not(.active-menu) {
        .nav__link-text {
          color: $mid-grey;
        }

        .nav__link-arrow {
          opacity: 0.5;
        }
      }
    }
  }

  &__sublinks {
    overflow: hidden;
    max-height: 0px;
    transition: all 1.2s;

    .active-menu & {
      max-height: 100vh;
      margin-bottom: 40px;
    }
  }

  &__link,
  &__sublink {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &-text {
      @include h3;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
      text-transform: uppercase;
      padding: $spacing-xs 0;
      transition: $anime-300;
    }

    &-arrow {
      width: $spacing-m;
      height: $spacing-m;
      margin-left: $spacing-xxs;
      transition: $anime-300;

      .active-menu & {
        opacity: 1;
        transform: rotate(-180deg);
      }
    }
  }

  &__link {
    &-text {
      position: relative;
    }
  }

  &__sublink {
    .nav__link-text {
      padding-left: 24px;
    }
  }

  &__buttons {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $cream-tint;
    border-top: 1px solid $line-grey;
    transition: all 0.3s ease-in-out 0.6s;

    &-hide {
      opacity: 0;
      visibility: hidden;
    }

    a {
      @include cta;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      height: 50px;
      text-transform: uppercase;

      &:first-child {
        border-right: 1px solid $line-grey;
      }
    }
  }
}
