$height: 100vh;

// For desktop
@media #{$large-up} {
  .wine-club-login {
    .form-bg {
      background: url('/assets/images/wine_club/VE_Checkout.jpg') no-repeat center;
    }

    .form-flexbox-inner {
      h1 {
        margin-bottom: $spacing-xxl-5;
      }

      .form-container-flexbox {
        .wine-club-login__form-left {
          align-items: flex-end;
          margin-right: $spacing-s;

          .form-element-row {
            margin-bottom: 0;
          }
        }
        .wine-club-login__form-right {
          align-items: flex-start;
          justify-content: flex-start;
          margin-left: $spacing-s;
        }
      }
    }
  }
}

// For mobile
@media #{$small-medium} {
  .wine-club-login {
    .form-bg {
      background: url('/assets/images/wine_club/VE_Checkout_Mobile.jpg') no-repeat center;
    }

    .form-container-flexbox {
      flex-direction: column;

      .wine-club-login__form-left {
        .form-element-row {
          margin-bottom: $spacing-xxl-2;
        }
      }
    }
  }
}

// Common
.wine-club-login {
  @include form(300px, 80%, $white, $white);

  .form-bg {
    height: $height;
    background-size: cover;
  }

  .form-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $height;
    background-color: $black;
    opacity: .6;
  }

  .form-flexbox {
    position: absolute;
    height: $height;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-flexbox-inner {
      position: relative;
      display: flex;
      flex-direction: column;

      h1 {
        @include h3-white;
        text-transform: capitalize;
        text-align: center;
        margin-top: 0;
      }

      .form-container-flexbox {
        display: flex;

        .form-wrap {
          h4 {
            @include h4-white;
            text-transform: uppercase;
            margin: 0 0 $spacing-xxs;
          }
        }

        .wine-club-login__form-right {
          p {
            width: 100%;
            text-align: left;
            margin: 0 0 16px;
          }
          button {
            margin-top: 0;
          }
        }
      }
    }
  }
}
