$signup-height: 48px;

// For desktop
@media #{$large-up} {
  .sticky-signup {
    &.sticky-signup__extend {
      height: 185px !important;
    }

    &__header span {
      width: 95% !important;
    }

    &__form {
      > div {
        flex-direction: row !important;

        input {
          width: 300px;
          margin-top: $spacing-m !important;

          &:first-child {
            margin-right: $spacing-m;
          }
        }
      }
    }
  }
}

// For mobile
.sticky-signup {
  position: fixed;
  z-index: 250;
  bottom: 0;
  height: $signup-height;
  width: 100%;
  background-color: $black;
  padding: 0 $spacing-s 0 $spacing-xl;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  transition: $anime-500;

  &.sticky-signup__extend {
    height: 245px;
  }
  
  &.sticky-signup__hide {
    bottom: -$signup-height;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    span {
      @include cta-white;
      width: 85%;
      height: 100%;
      line-height: $signup-height;
      text-transform: uppercase;
      cursor: pointer;
    }

    img {
      cursor: pointer;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    > div {
      display: flex;
      flex-direction: column;

      input {
        @include body-small-white;
        background-color: transparent;
        padding-bottom: 6px;
        border: none;
        border-bottom: 1px solid $white;
        outline: none;
        margin-top: $spacing-xxl-2;
  
        &:first-child {
          margin-top: $spacing-m;
        }
      }
    }

    button {
      border: 1px solid $white;
      margin-top: $spacing-xxl-2;
    }
  }
}
