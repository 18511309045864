// For desktop
@media #{$large-up} {
  .credit-cards-table {
    width: 600px;

    &__header-desktop {
      display: flex;
      justify-content: space-evenly;

      span {
        width: 33.33%;
        text-align: center;
        padding: $spacing-l 0;
      }
    }

    &__header-mobile {
      display: none;
    }

    &__row {
      &-col {
        display: none;
      }
    }
  }
}

// For mobile
@media #{$small-medium} {
  .credit-cards-table {
    width: 100%;

    &__header-desktop {
      display: none;
    }

    &__header-mobile {
      padding: $spacing-l 0;
    }

    &__row {
      display: flex;

      &-col {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        span {
          @include body-small;
          margin-bottom: $spacing-xxs;
        }
      }
      
      &-data {
        flex-grow: 3;
        flex-direction: column;

        span {
          margin-bottom: $spacing-xxs;
        }
      }
    }
  }
}

// Common
.credit-cards-table {
  &__header-desktop, &__header-mobile {
    border-top: 1px solid $line-grey;
    border-bottom: 1px solid $line-grey;
    margin-top: $spacing-xl;

    span {
      @include cta;
      text-transform: uppercase;
    }
  }

  &__row {
    padding: $spacing-l 0;
    border-bottom: 1px solid $line-grey;

    &-data {
      display: flex;

      span {
        @include body-small;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.add-entry-link {
  @include cta;
  display: block;
  width: fit-content;
  text-transform: uppercase;
  color: $white;
  padding: $spacing-s $spacing-m;
  background-color: $black;
  margin-top: $spacing-xl;
  cursor: pointer;
}

@include form(300px, 80%, $line-grey, $black);

.dropdown-new-address {
  height: 42px;
}