:root {
  --black: #000000;
  --dark-grey: #3f4445;
  --dark-mid-grey: #72797a;
  --mid-grey: #b8bdb7;
  --light-grey: #f5f3f3;
  --line-grey: #d6d6d6;
  --white: #ffffff;
  --cream-tint: #f9faf9;
  --green: #0f5e34;
}

@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'global';

@import 'components';
@import 'pages';
