* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 100%;
  min-height: 100%;

  &.disabled {
    overflow-y: hidden;
  }
}

/**
 * For desktop, blur main and footer only.
 * Due to the structure of the nav, it's impossible
 * to blur the nav bar w/o blurring the side panel
 * as well, since the side panel is contained in the
 * nav bar.
 */
@media #{$large-up} {
  body {
    &.active-options, &.active-search, &.active-minicart, &.active-modal, &.active-tastings {
      &:after {
        z-index: 299;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,0.4);
      }
    }
  }
}

/**
 * For mobile, blur everything, i.e. nav, main and footer.
 * As the sliding panels are located directly underneath
 * the body tag.
 */
body {
  position:relative;
  overflow-x: hidden;
  font-family: $body-font;
  background-color: $cream-tint;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;

  main {
    flex: 1;
  }

  .nav, main, footer {
    transition: $anime-300;
  }

  &.active-navigation {
    &:after {
      z-index: 299;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0,0,0,0.4);
    }
  }

  cursor: url('/assets/images/icons/cursors/cursor_green.svg'), auto;
}

a {
  text-decoration: none;
  color: $black;
}

input {
  border-radius: 0;
  border: none;
  outline: none;
}

input[type="search"] {
  -webkit-appearance: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
}

p {
  font-family: $body-font;
}

var {
  font-style: normal;
}

h1, .h1 {
  @include h1;
}

.h1-white {
  @include h1-white;
}

.h1-display {
  @include display;
}

.h1-display-white {
  @include display-white;
}

h2, .h2 {
  @include h2;
}

.h2-white {
  @include h2-white;
}

h3, .h3 {
  @include h3;
}

.h3-white {
  @include h3-white;
}

h4, .h4 {
  @include h4;
}

.h4-white {
  @include h4-white;
}

p, .body, span {
  @include body;
}

.body-small {
  @include body-small;
}

.cta {
  @include cta;
}

.cta-white {
  @include cta-white;
}

label, .label {
  @include label;
}

.label-white {
  @include label-white;
}

.sr-only {
  @include sr-only;
}

.sr-only-focusable {
  @include sr-only-focusable;
}

/**
 * Scrollbar customisation
 */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #666666;
  border: 0;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/**
 * Text selection color
 */
::-moz-selection { // For Firefox
  color: $white;
  background: $green;
}

::selection {
  color: $white;
  background: $green;
}

.hidden {
  display: none;
}