// For desktop
@media #{$large-up} {
  .tastings-options {
    flex-direction: row !important;
    height: 260px !important;

    > div {
      width: 50% !important;
    }

    &__text {
      height: revert !important;

      &-links {
        flex-direction: row !important;
  
        a {
          width: 175px !important;
          margin-right: $spacing-m;
          margin-bottom: 0 !important;
  
          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }

    &__image {
      height: revert !important;
    }
  }
}

// For mobile
#tastings-options__mask {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .active-tastings & {
    visibility: visible;
    opacity: 1;
  }
}

.tastings-options {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 620px;
  background-color: $white;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  transition: $anime-500;
  z-index: 99;

  .active-tastings & {
    visibility: visible;
    opacity: 1;
  }

  > div {
    width: 100%;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 405px;
    padding: $spacing-xl;

    h3 {
      margin: 0 0 $spacing-s;
    }

    p {
      @include body-small;
      width: 100%;
    }

    span {
      @include cta;
      display: block;
      text-transform: uppercase;
      margin-bottom: $spacing-s;
    }

    &-links {
      display: flex;
      flex-direction: column;

      a {
        @include cta-white;
        display: block;
        width: 100%;
        height: 36px;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        background-color: $black;
        margin-bottom: $spacing-m;
      }
    }
  }

  &__image {
    position: relative;
    height: 215px;
    background-image: url('/assets/images/experiences/desktop/Experience POPUP_SHOTBYTHOM-9759.jpg');
    background-size: cover;
    background-position: center;

    &-close {
      position: absolute;
      top: $spacing-m;
      right: $spacing-m;
      width: 24px;
      height: 24px;
      background: transparent url('/assets/images/icons/symbols/close_white.svg') no-repeat right center;
      cursor: pointer;
    }
  }
}
