// For desktop
@media #{$large-up} {
  .ack {
    &-panel {
      width: 400px !important;
    }
  }
}

// For mobile
.ack {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $mid-grey;
  background-position: center;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);

  &-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        @include body;
        width: 90%;
        color: $white;
        margin: 0;
      }

      a {
        margin-top: 36px;
      }
    }
  }
}
