// For desktop
@media #{$large-up} {
  .location__hero {
    background-image: url('/assets/images/location/desktop/VE_Location_2-11-20_0495.jpg') !important;

    &-caption {
      max-width: 400px;
      font-size: 66px !important;
      letter-spacing: -2.5px !important;
    }
  }

  .location__light {
    padding: 120px 0 0;
    margin: 0 !important;

    h3 {
      width: 690px !important;
    }
  }

  .location__on-location {
    display: flex;

    > div {
      width: 50% !important;
    }

    &-photo {
      img {
        width: 330px !important;
      }

      figcaption {
        @include body-small;
        margin-top: $spacing-s;
      }
    }

    &-caption {
      > div {
        width: 380px !important;
      }
    }
  }

  .location__map {
    flex-direction: row !important;
    margin-right: $spacing-xl;
    margin-left: $spacing-xl;

    > div {
      width: 50%;
      overflow: hidden;
    }
    
    &-legends {
      display: flex;
      justify-content: center;
      
      &-wrap {
        width: 480px;

        h2 {
          width: 100% !important;
          font-size: 32px !important;
          text-align: left !important;
          margin: 0 !important;
        }
        
        &__columns {
          margin-top: $spacing-xxl-6;

          > div {
            margin: 0 !important;
          }
        }
      }
    }

    img {
      width: 50% !important;
      height: calc(0.7529 * 50%) !important;
    }
  }

  .location__address {
    padding: 50px 0px 100px 0px !important;

    h1 {
      width: 900px !important;
      font-size: 52px !important;
    }
  }

  .location__tail {
    margin: 0 $spacing-xl $spacing-xl !important;
    width: revert !important;
    height: 100vh !important;
  }
}

// For mobile
.location__hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: url('/assets/images/location/mobile/VE_Location_2-11-20_0495.jpg');
  background-size: cover;
  background-position: center;

  &-caption {
    @include h2-white;
    width: 60%;
    text-transform: uppercase;
    margin: 0;
  }
}

.location__light {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: $spacing-xxl-6 0;

  h3 {
    @include h3-caps;
    width: 80%;
    text-transform: uppercase;
    margin: 0;
  }
}

.location__on-location {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-photo {
    figure {
      margin: 0 $spacing-xl;

      img {
        width: 100%;
      }
    }
  }

  &-caption {
    > div {
      margin: $spacing-xxl-5 $spacing-xl;
      width: 100%;

      h4 {
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: $spacing-xxs;
      }
      
      span {
        @include cta;
        text-transform: uppercase;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.location__map {
  display: flex;
  flex-direction: column-reverse;

  &-legends {
    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      h2 {
        width: 80%;
        font-size: 25px;
        text-align: center;
        text-transform: uppercase;
        margin: $spacing-xxl-5 0;
      }
  
      &__columns {
        display: flex;
        width: 100%;

        > div {
          text-align: left;
          width: 50%;
    
          &:first-child {
            margin: 0 0 0 $spacing-xl;
          }
          &:last-child {
            margin: 0 $spacing-xl 0 0;
          }
    
          span {
            @include cta;
            text-transform: uppercase;
          }
    
          ul {
            @include body-small;
            list-style-type: none;
            padding: 0;
            margin: $spacing-xs 0;
          }
        }
      }
    }
  }

  img {
    height: calc(1.5058 * 100vw);
    background-image: url('/assets/images/location/desktop/VE_Location_Map.png');
    background-size: cover;
    background-position: center;
    mix-blend-mode: multiply;
  }
}

.location__address {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 50px 0px;

  .line_1 {
    font-family: 'Bond4F';
    letter-spacing: normal;
  }

  .line_4 {
    font-family: 'Bond4F';
    letter-spacing: normal;
  }

  h1 {
    width: 90%;
    text-align: center;
    margin: 0;
    font-size: 32px;
    line-height: normal;
  }

  a {
    margin-top: $spacing-xxl-3;
  }
}

.location__tail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vw;
  background-image: url('/assets/images/location/desktop/VE_Location_2-11-20_0356.jpg');
  background-size: cover;
  background-position: center;
  margin: 0 $spacing-xl $spacing-xl;
}
