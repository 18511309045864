.nav__search {
  width: 100%;
  padding: $spacing-xxl-4 0 0;
  position: relative;

  label {
    @include body;
    letter-spacing: 0;
  }

  &-form {
    position: relative;
  }

  &-input {
    width: 100%;
    background-color: transparent;
    color: $dark-mid-grey;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $body-font;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.8px;
    line-height: normal !important;
    padding: $spacing-s 0 !important;
    border: none !important;
    border-bottom: 1px solid $black !important;
    outline: none;
  }

  .mobile-search-input {
    color: $black !important;
  }

  &-submit {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: $spacing-s;
    height: $spacing-s;
    background-color: transparent;
    padding: 0;
    border: 1px solid $black;
    border-radius: 19px;
    cursor: pointer;
  }

  &-clear {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: $spacing-m;
    height: $spacing-m;
    background-color: $black;
    padding: 0;
    border-radius: 50%;
    outline: none;
    cursor: pointer;

    &::after {
      content: "\d7";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: $spacing-xl;
    }

    &-visible {
      display: block;
    }
  }

  &-results {
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    height: 0;
    transition: all 0.3s ease-in-out 0.3s;

    .search-results-available & {
      opacity: 1;
      visibility: visible;
      @media #{$large-up} {
        height: calc(100vh - 172px) !important;
      }
      height: calc(100vh - 152px);
    }

    &-list {
      padding: 0;
      margin: 0;
    }
  }

  &-result {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &:last-child {
      a {
        border-bottom: none;
      }
    }

    &-link {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: stretch;
      padding: $spacing-xl 0;
      border-bottom: 1px solid $mid-grey;
    }

    &-image {
      width: 83px;
      height: 99px;
    }

    &-text {
      display: flex;
      flex-flow: column nowrap;
      flex-shrink: 0;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(100% - 83px);
      padding: 0 $spacing-s;
    }

    &-title,
    &-subtitle {
      @include body-small;
      margin: 0;
    }
  }

  &-empty {
    width: 100%;
    text-align: center;
    padding-top: $spacing-xxl-3;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out 0.6s;

    .search-results-available & {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0s;
    }

    &-text {
      @include body-small;
      margin: 0;
    }
  }

  .input-box {
    width: 100%;
    height: 30px;
    background: none;
    border-bottom: 1px solid black;
  }
}
