// For desktop
@media #{$large-up} {
  .club-list__note p {
    width: 390px;
  }

  .club-list__info {
    display: flex;

    > div {
      width: 100%;

      &:last-child {
        margin-top: 0 !important;
        margin-left: $spacing-xl;
      }
    }
  }

  .cart-item {
    &__details {
      &__specs {
        flex-direction: row !important;
        align-items: center;
        width: 80%;
        margin-right: 0 !important;

        &-promo span {
          display: block;
        }

        &-quantity {
          width: 72px !important;
          height: 24px !important;
          margin-top: 0 !important;

          &__add {
            background-position: center !important;
          }
          &__amount {
            font-size: 14px !important;
            border-left: 1px solid $line-grey;
            border-right: 1px solid $line-grey;
          }
          &__remove {
            background-position: center !important;
          }
        }
      }

      &__price {
        justify-content: center;
      }
    }
  }
}

// For mobile
.club-list__summary {
  @include label;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  text-transform: uppercase;
  border-top: 1px solid $line-grey;
  border-bottom: 1px solid $line-grey;
  margin-top: $spacing-xl;

  span {
    @include label;
  }
}

.club-list__fold-title {
  margin: 0 115px !important;
}

.club-list__fold-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-l 0;
  border-top: 1px solid $line-grey;
  border-bottom: 1px solid $line-grey;
  margin-top: $spacing-xl;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: $spacing-xxl-3;

    &:last-child {
      margin-right: 0;
    }

    .cta {
      font-size: 12px;
      text-transform: uppercase;
    }

    span:last-child {
      font-size: 14px;
      margin-top: $spacing-xs;
    }
  }
}

.club-list__selection {
  border: 1px solid $line-grey;
  margin-top: $spacing-m;

  .cta {
    text-align: center;
    text-transform: uppercase;
    margin: $spacing-xxl 0 $spacing-s;
  }

  &-bottles {
    display: grid;
    grid-template-columns: repeat(3, 33.33%);

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      img {
        width: 100%;
      }

      span {
        font-size: 14px;
      }
    }
  }

  &-edit {
    @include cta;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: fit-content;
    text-transform: uppercase;
    color: $white;
    padding: $spacing-s $spacing-m;
    background-color: $black;
    margin-bottom: $spacing-xl;
    cursor: pointer;
  }
}

.club-list__benefits {
  text-align: center;
  background-color: $black;
  padding: $spacing-xxl-2 $spacing-l $spacing-xxl-2;
  margin-top: $spacing-xl;

  h3 {
    margin: 0 0 $spacing-xl;
  }

  &-items {
    display: flex;
    justify-content: space-between;

    > div {
      width: calc(50% - #{$spacing-s} / 2);
    }
    
    p {
      @include body-small-white;
      padding-bottom: $spacing-s;
      border-bottom: 1px solid $white;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.cart-item {
  display: flex;
  padding: $spacing-m 0;
  border-bottom: 1px solid $line-grey;

  &:last-child {
    border-bottom: none;
  }
  
  &__thumb {
    img {
      width: $spacing-xxl-6;
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    width: calc(100% - #{$spacing-xxl-6});
    
    &__specs {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-right: $spacing-xs;

      &-col span {
        display: block;
      }

      &-year {
        @include body-small;
      }
      
      &-name {
        @include body-small;
        max-width: 200px;
      }

      &-promo__club-list {
        max-width: 80px;

        span {
          @include body-small;
          font-style: italic;
        }
      }

      &-quantity {
        display: flex;
        width: 105px;
        height: 40px;
        border: 1px solid $line-grey;
        margin-top: $spacing-s;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% / 3);
          color: $mid-grey;
        }
        
        &__add {
          background: $white url('/assets/images/icons/symbols/minus_grey.svg') no-repeat right center;
          cursor: pointer;
        }
        &__amount {
          font-family: $bond4f;
          font-size: 16px;
          font-weight: 500;
          background-color: $white;
          cursor: not-allowed;
        }
        &__remove {
          background: $white url('/assets/images/icons/symbols/plus_grey.svg') no-repeat left center;
          cursor: pointer;
        }
      }
    }

    &__price {
      @include body;
      display: flex;
      flex-direction: column-reverse;
      margin-right: $spacing-s;

      span {
        @include body;
        display: block;
      }
    }
  }

  &.item-sold-out {
    span, div {
      color: $mid-grey;
      cursor: not-allowed !important;
    }
    .cart-action__quantity {
      visibility: hidden;
    }
  }
}

.club-list__note {
  border-bottom: 1px solid $line-grey;
  
  p {
    @include body-small;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
  }
}

.club-list__info {
  padding: $spacing-l 0;
  
  > div {
    display: flex;

    &:last-child {
      margin-top: $spacing-xl;
    }

    .club-list__info__entries {
      width: calc(100% - 75px);
      padding-right: 25px;

      > span {
        @include cta;
        display: block;
        text-transform: uppercase;
      }

      p {
        margin-bottom: 0;

        span {
          @include body-small;
          display: block;
        }
      }
    }

    button {
      @include cta;
      width: 75px;
      height: 36px;
      text-align: center;
      text-transform: uppercase;
      background-color: $cream-tint;
      border: 1px solid $line-grey;
      border-radius: 0;
      cursor: pointer;
    }
  }
}

.club-list__save {
  @include cta;
  width: 100%;
  height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  background-color: $black;
  border: 0;
  border-radius: 0;
  margin-top: $spacing-m;
  outline: none;
  cursor: pointer;
}
