// For desktop
@media #{$large-up} {
  .responsible-winery__title {
    margin-top: 0;
    margin-bottom: $spacing-xxl-7;
  }
}

// For mobile
@media #{$small-medium} {
  .responsible-winery__title {
    margin-top: $spacing-xxl-3;
    margin-bottom: $spacing-xxl-5;
  }
}
