// For desktop
@media #{$large-up} {
  .section-frame {
    section.category-hero {
      padding-bottom: $spacing-xl !important;

      &__text {
        p {
          width: 40% !important;
          margin-top: 45px !important;
        }
      }
    }

    section.section-header {
      flex-direction: row !important;
      border-bottom: 0 !important;
      margin-bottom: $spacing-s;

      h3,
      h1 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-family: "Portrait", "serif" !important;
        margin: 0px !important;
        font-size: 25px;
        font-weight: 300;
        letter-spacing: 0.78px;
        text-transform: uppercase;
      }

      #filter-keys {
        width: 75% !important;
        margin-top: 0 !important;
      }
    }
  }

  .category-filter {
    width: 246px !important;
    height: 104px !important;

    &__expanded {
      width: 772px !important;
      height: 362px !important;
    }

    .filter-handler {
      width: 246px !important;
      height: 104px !important;

      &__arrow {
        margin-bottom: $spacing-l !important;
      }

      &__caption {
        font-size: 18px !important;
        line-height: 0.67 !important;
        letter-spacing: 1.5px !important;
      }
    }

    .filter-wrap {
      .filter-close {
        display: block !important;
        position: absolute;
        top: $spacing-m;
        right: $spacing-m;
        width: 24px;
        height: 24px;
        background-image: url("/assets/images/icons/symbols/close_white.svg");
        cursor: pointer;
      }

      .filter-content {
        &__options {
          width: 480px !important;
          height: auto !important;
          padding: 0 !important;

          h3 {
            margin-top: 0 !important;
          }

          .filter-options__wrap {
            flex-direction: row !important;
            flex-wrap: wrap !important;

            > div {
              margin-right: $spacing-xs;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

// For mobile
.section-frame {
  section.filter-result {
    border-top: 0;
  }

  section.category-hero {
    display: flex;
    flex-direction: column;
    border: 0;
    padding-bottom: 0;

    &__breadcrumb {
      .crumb {
        @include body-small;
      }
    }

    &__text {
      p {
        @include h3;
        line-height: revert;
        width: 100%;
        margin-top: $spacing-l;
      }
    }
  }

  section.section-header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: 0;
    border: 0;
    border-bottom: 1px solid $line-grey;

    h3 {
      @include h3-caps;
      text-transform: uppercase;
      margin-top: $spacing-xxl-7;
    }

    h1 {
      @include h3-caps;
      margin-top: $spacing-xxl-7;
      font-family: "Portrait", "serif" !important;
      font-size: 25px;
      font-weight: 300;
      letter-spacing: 0.78px;
      text-transform: uppercase;
    }

    #filter-keys {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      min-height: 40px;
      margin: $spacing-l 0;

      > div {
        display: flex;
        height: 32px;
        text-transform: uppercase;
        padding: 0 $spacing-s 0 $spacing-xs;
        background-color: $green;
        margin-right: $spacing-xs;
        margin-bottom: $spacing-xs;

        .filter-key__close {
          width: 16px;
          height: 100%;
          background: transparent
            url("/assets/images/icons/symbols/close_white.svg") no-repeat center;
          background-size: contain;
          margin-right: $spacing-xxs;
          cursor: pointer;
        }

        span {
          @include cta-white;
          line-height: 34px;
        }
      }
    }

    .section-header__sortby {
      width: auto;
    }

    &.filter-header {
      padding-top: $spacing-xl;
    }
  }
}

.category-filter {
  position: fixed;
  z-index: 250;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 176px;
  height: 76px;
  background-color: $green;
  transition: $anime-500;
  cursor: url("/assets/images/icons/cursors/cursor_white.svg"), auto;

  &__expanded {
    width: 100%;
    height: 422px;

    // Reserved for mask closing
    // &::before {
    //   content: '';
    //   position: fixed;
    //   top: calc(-100vh + 362px);
    //   bottom: 0;
    //   width: 100%;
    //   height: calc(100vh - 362px);
    //   opacity: 0;
    //   cursor: pointer;
    // }
  }

  .filter-handler {
    z-index: 250;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 176px;
    height: 76px;
    cursor: pointer;
    transition: $anime-300;

    &__hide {
      visibility: hidden;
      opacity: 0;
    }

    &__arrow {
      width: 24px;
      height: 24px;
      background-image: url("/assets/images/icons/bow-arrows/bow-arrow_down_white.svg");
      margin-bottom: $spacing-s;
      transform: rotate(180deg);
    }

    &__caption {
      @include cta-white;
      font-size: 14px;
      line-height: 0.86;
      letter-spacing: 0.9px;
      text-transform: uppercase;
    }
  }

  .filter-wrap {
    opacity: 0;
    height: 100%;
    transition: $anime-200;

    &__show {
      opacity: 1;
      transition: all 0.4s ease-in-out 0.2s;
    }

    .filter-close {
      display: block !important;
      position: absolute;
      top: $spacing-m;
      right: $spacing-m;
      width: 24px;
      height: 24px;
      background-image: url("/assets/images/icons/symbols/close_white.svg");
      cursor: pointer;
    }

    .filter-tab {
      display: flex;
      justify-content: center;
      margin-top: $spacing-m;

      &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: $spacing-xl;

        &:last-child {
          margin-right: 0;
        }

        > div {
          opacity: 0;
          width: 6px;
          height: 6px;
          background-color: $white;
          border-radius: 50%;
          margin-bottom: $spacing-xs;
        }

        > div.selected-filter__dot {
          opacity: 1;
          transition: $anime-200;
        }

        button {
          @include label-white;
          text-transform: uppercase;
          padding: 0;
          background: none;
          border: none;
          outline: none;
          opacity: 0.4;
          cursor: pointer;

          &.selected-filter {
            opacity: 1;
          }
        }
      }
    }

    .filter-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 44px);

      &__options {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        height: 350px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 15%;
        transition: all 0.2s ease-in-out;

        h3 {
          text-align: center;
          color: $white;
          margin-top: $spacing-l;
        }

        .filter-options__wrap {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          width: 100%;
          justify-content: center;

          > div {
            @include cta-white;
            height: 32px;
            line-height: 32px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 24px;
            border: 1px solid $white;
            margin-bottom: $spacing-xs;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0;
            }

            &.selected-filter-key {
              background: $white;
              color: $green;
            }
          }
        }

        &.show-tab-content {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.dropdown-sortby {
  width: 150px;
  height: 32px;

  input,
  ul li,
  option {
    text-transform: uppercase;
  }
}

// Sticky images
.category__sticky-splash-1 {
  background-image: url("/assets/images/category/VE_Category_1_DSC_0198.jpg");
}

.category__sticky-splash-2 {
  background-image: url("/assets/images/category/VE_Category_1_DSC_0198.jpg");
}

.category__sticky-splash-3 {
  background-image: url("/assets/images/category/VE_Category_1_WinterMenu_Oct1.jpg");
}

.category__sticky-splash-4 {
  background-image: url("/assets/images/category/20007_press_VOYAGER ESTATE_Adrian Gaut_2-11-20_1026.jpg");
}

.category__sticky-splash-5 {
  background-image: url("/assets/images/category/20007_press_VOYAGER ESTATE_Adrian Gaut_2-11-20_0795.jpg");
}

.category__sticky-splash-6 {
  background-image: url("/assets/images/category/20007_press_VOYAGER ESTATE_Adrian Gaut_2-11-20_0557.jpg");
}
