/**
 * Common styles for the "content" section
 */

// For desktop
@media #{$large-up} {
  main {
    .sticky-placeholder {
      position: relative;
      overflow: hidden;

      &.fix-content {
        display: flex;
        flex-direction: column-reverse;
      }

      .sticky-image {
        width: 100%;
        height: 100vh !important;
      }
    }

    .sticky-half-width {
      position: fixed !important;
      top: 0;
      width: calc(50% - #{$spacing-xl}) !important;
      pointer-events: none;
    }

    .sticky-full-width {
      position: fixed !important;
      top: 0;
      right: 0;
      width: 50% !important;
      pointer-events: none;
    }

    .carousel {
      margin-bottom: 0 !important;

      &__slides {
        height: 100vh !important;
      }

      &__controls {
        @include label-white;
        position: absolute;
        z-index: 200;
        right: 0;
        bottom: 0;
        display: flex !important;
        list-style-type: none;
        padding: 0;
        margin: 0 $spacing-xxs $spacing-m;

        &-item {
          width: $spacing-xl;
          height: $spacing-xl;
          padding: 2px 0 0 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 0px;
          border-radius: 50%;
          margin-right: $spacing-xxs;
          cursor: pointer;
          transition: all 0.2s;

          &.current {
            color: $black;
            background-color: $white;
          }
        }
      }

      &__meta {
        position: absolute;
        z-index: 100;
        top: 0;
        height: 100%;

        .meta-title {
          @include h1-white;
        }

        .meta-text {
          @include h4-white;
          position: absolute;
          margin: 0;
        }

        .meta-link {
          @include label;
          position: absolute;
          background-color: $white;
          margin: 0 auto;
          @media #{$medium-up} {
            margin: 0;
          }
        }
      }
    }

    .product-gallery {
      margin-top: 0 !important;
      grid-template-columns: repeat(2, 50%) !important;

      &.product-gallery__half-width {
        margin-right: 24px;
        margin-left: -24px;
      }

      &.product-gallery__full-width {
        grid-template-columns: repeat(4, 25%) !important;

        margin-left: -24px;
        width: calc(100% + 24px);

        > :last-child,
        > :nth-last-child(2) {
          margin-bottom: $spacing-xxl-3 !important;
        }
      }

      &__slide {
        padding: $spacing-s $spacing-xl;
        border-right: 1px solid $line-grey;

        .blend-img {
          mix-blend-mode: multiply;
        }

        .slide-meta__name {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }
      }
    }

    .product-gallery {
      margin-top: 0 !important;
      grid-template-columns: repeat(2, 50%) !important;

      &.product-gallery__half-width {
        margin-right: 24px;
        margin-left: -24px;
      }

      &.product-gallery__full-width {
        grid-template-columns: repeat(4, 25%) !important;

        margin-left: -24px;
        width: calc(100% + 24px);

        > :last-child,
        > :nth-last-child(2) {
          margin-bottom: $spacing-xxl-3 !important;
        }
      }

      &__slide {
        padding: $spacing-s $spacing-xl;
        border-right: 1px solid $line-grey;

        .blend-img {
          mix-blend-mode: multiply;
        }

        .slide-meta__name {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }
      }
    }

    .zoom-twin {
      &-card,
      &-splash {
        overflow: hidden;
        height: 100vh;
        width: 50%;
      }

      &-card {
        position: relative;

        // Do NOT change the total height of .card-meta
        .card-meta {
          position: absolute;
          top: calc(50% + 46px);
          transform: translateY(-50%);
          width: 285px;
          text-align: center;
          transition: all 0.5s ease-in-out;

          &.meta-left {
            right: 0;
          }
          &.meta-right {
            left: 0;
          }

          &.card-left-centered {
            top: calc(50% - 20px);
            right: 50%;
            transform: translate(50%, -50%);

            .card-title,
            .card-text,
            .card-cta {
              opacity: 1;
            }
          }
          &.card-right-centered {
            top: calc(50% - 20px);
            left: 50%;
            transform: translate(-50%, -50%);

            .card-title,
            .card-text,
            .card-cta {
              opacity: 1;
            }
          }

          .card-title {
            margin-bottom: $spacing-xxl-2 !important;
            opacity: 0;
            transition: all 0.2s ease-in-out 0.5s;
          }

          .card-thumb {
            display: block !important;
            width: 285px;
            height: 369px;
            background-position: center;
            background-size: cover;
          }

          .card-text {
            @include body-small;
            display: block !important;
            width: 330px;
            height: 80px;
            margin-top: $spacing-xxl-4;
            margin-bottom: $spacing-xl;
            opacity: 0;
            transition: all 0.2s ease-in-out 0.5s;
          }

          .card-cta {
            opacity: 0;
            transition: all 0.5s ease-in-out 0.6s;
          }
        }
      }

      &-splash {
        position: relative;

        .splash-thumb {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 285px;
          height: 369px !important;
          transition: all 0.5s ease-in-out;

          &.thumb-left {
            right: 0;
          }
          &.thumb-right {
            left: 0;
          }

          &.splash-centered {
            width: 100%;
            height: 100% !important;
          }
        }
      }
    }

    .slides-twin-scroll {
      .sts-scroll {
        section {
          flex-direction: row !important;
          overflow-y: hidden;

          .sts-scroll__content-wrap {
            margin: $spacing-xxl-6 0 !important;

            .prod-year-table {
              margin-top: $spacing-xxl-5 !important;

              .dropdown-year-table {
                display: none;
              }

              &__tabs {
                display: flex !important;

                span {
                  @include cta;
                  display: flex;
                  align-items: center;
                  color: $mid-grey;
                  margin-left: $spacing-m;
                  cursor: pointer;
                  transition: $anime-200;

                  &:first-child {
                    margin-left: 0;
                  }

                  &.prod-year-table__selected-year {
                    color: $black;

                    &::before {
                      content: "";
                      display: block;
                      width: 6px;
                      height: 6px;
                      background-color: $black;
                      border-radius: 50%;
                      margin-right: $spacing-xs;
                    }
                  }
                }
              }
            }

            .scroll-text-subsection {
              transition: $anime-300;
            }

            .subsection-fade-out {
              opacity: 0.2;
            }
          }
        }

        &__award-section {
          &__wrap {
            .wine-award {
              top: $spacing-xxl-2 !important;
            }

            p {
              width: 60% !important;
            }
          }
        }
      }

      .sticky-placeholder {
        display: block !important;
        display: flex !important;

        .sts-slides {
          position: relative;
          width: 100%;
          height: 100vh;

          &__hide {
            opacity: 0;
          }

          > div {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: $anime-300;
          }

          &__1 {
            background-image: url("/assets/images/category/VE_Category_1_DSC_0198.jpg");
          }

          &__2 {
            background-image: url("/assets/images/category/VE_Category_1_WinterMenu_Oct1.jpg");
          }

          &__3 {
            background-image: url("/assets/images/homepage/desktop/experiences/VE_Homepage_Experiences1_SHOTBYTHOM-0710-2.jpg");
          }
        }
      }
    }

    .h1-h2 {
      font-size: 52px !important;
      letter-spacing: -2px !important;
    }
  }
}

.main-content {
  margin: 0 auto;
  padding: 40px 0;
  width: 90%;
  max-width: 1124px;
  h1 {
    @include h3;
    margin-bottom: 20px !important;
  }
  .defaultBtn {
    @include cta-white;
    span {
      @include cta-white;
    }
    display: block;
    width: 100%;
    max-width: 450px;
    height: 36px;
    text-align: center;
    text-transform: uppercase;
    line-height: 36px;
    padding: 0;
    background-color: $black;
    margin-top: $spacing-xxl;
    cursor: pointer;
  }
  fieldset {
    padding: 0;
    border: none;
    max-width: 450px;

    & > div {
      margin-bottom: 20px;
      img {
        margin-top: 6px;
      }
      &:nth-child(2) {
        select {
          width: 30% !important;
        }
      }
    }
    legend {
      padding-left: 0;
      margin-left: 0;
      @include h3;
      padding-bottom: 20px;
      padding-top: 6px;
    }
  }
  label {
    padding-bottom: 4px;
    display: block;
  }
  select {
    width: 100% !important;
    padding: 0.5rem 0;
    border-radius: 0px;
  }
  &.v65-group {
    flex-direction: row;
    label {
      display: inline;
      padding-bottom: 0;
    }
    input {
      width: auto !important;
      margin-right: 10px;
    }
  }
  input:not([type="checkbox"]) {
    width: 100% !important;
    background-color: transparent;
    color: $dark-mid-grey;
    text-decoration: none;
    font-family: $body-font;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.8px;
    line-height: normal !important;
    padding: $spacing-s 0 !important;
    border: none !important;
    border-bottom: 1px solid $black !important;
    outline: none;
  }
}

#v65-modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  #v65-modalViel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  #v65-modalContent {
    p {
      font-size: 13px;
    }
    p:last-child {
      display: none;
    }
  }
  #v65-modalContentWrapper {
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding: 1rem;
    width: 100%;
    max-width: 600px;
  }
  h3 {
    display: none;
  }
  img {
    align-self: flex-start;
  }
  fieldset {
    border: none;
    legend {
      padding-left: 0;
      margin-left: 0;
      @include h3;
      padding-bottom: 20px;
      padding-top: 6px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      &.v65-group {
        flex-direction: row;
        input {
          width: auto !important;
          margin-right: 10px;
        }
      }
      label {
        padding-bottom: 4px;
      }
      input {
        width: 100% !important;
        background-color: transparent;
        color: $dark-mid-grey;
        text-decoration: none;
        font-family: $body-font;
        font-size: 15px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.8px;
        line-height: normal !important;
        padding: $spacing-s 0 !important;
        border: none !important;
        border-bottom: 1px solid $black !important;
        outline: none;
      }
    }
    button {
      @include cta-white;
      span {
        @include cta-white;
      }
      display: block;
      width: 100%;
      height: 36px;
      text-align: center;
      text-transform: uppercase;
      line-height: 36px;
      padding: 0;
      background-color: $black;
      margin-top: $spacing-xxl;
      cursor: pointer;
    }
  }
  #v65-modalContentHeader {
    position: absolute;
    top: 1rem;
    right: 1rem;
    a {
      &:after {
        content: "×";
        display: inline;
        color: black;
        font-size: 28px;
      }
      span {
        display: none;
      }
    }
  }
}

// For mobile
main {
  background-color: $cream-tint;

  .nav-placeholder {
    height: $spacing-xxl-5;
  }

  // For fade-in animation
  .sliding-block {
    opacity: 0;
    margin-top: $spacing-xl;
    transition: all 1.4s ease-in-out;

    &__fade-in {
      opacity: 1;
      margin-top: 0;
    }
  }

  .section-frame {
    padding: 0 $spacing-xl $spacing-xl;

    > section {
      @media #{$large-up} {
        flex-direction: row;
        border-top: 1px solid $line-grey;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: $spacing-xl 0;

      > div {
        width: 100%;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  .sticky-placeholder {
    margin-bottom: 20px;
    .sticky-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 398px;
      background-position: center;
      background-size: cover;
    }
  }

  .section-reverse {
    @media #{$large-up} {
      flex-direction: row !important;
    }
    flex-direction: column-reverse !important;
  }

  .carousel {
    position: relative;
    width: 100%;

    &__slides {
      position: relative;
      height: 100vh;
    }

    &__slide {
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;

      &.current {
        z-index: 100;
        opacity: 1;
        // Fade out the current video faster to reduce
        // residue crossfade during the transition
        transition: opacity 0.2s ease-in-out;
      }

      video {
        position: relative;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
        height: 100vh;
      }
    }

    &__controls {
      display: none;
    }

    &__meta {
      margin-bottom: 30px;
      .meta-title {
        width: 300px;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
        @media #{$medium-up} {
          margin-right: initial;
          margin-left: initial;
        }
      }

      .meta-text {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        @media #{$medium-up} {
          margin-right: initial;
          margin-left: initial;
        }
      }

      .meta-link {
        display: block;
        width: 200px;
        height: 36px;
        text-transform: uppercase;
        text-align: center;
        line-height: 36px;
        margin-left: auto;
        margin-right: auto;
        @media #{$medium-up} {
          margin-right: initial;
          margin-left: initial;
        }
      }
    }
  }

  .product-gallery {
    margin-top: $spacing-xxl-4;
    display: grid;
    grid-template-columns: repeat(1, 100%);

    &.product-gallery__full-width {
      margin-top: 0 !important;
    }

    &__slide {
      @include h2;
      position: relative;
      display: inline-block;
      overflow: hidden;
      margin-bottom: $spacing-xxl-3;

      &:hover {
        .slide-meta__flag {
          left: -144px;
        }
        .slide-meta__cta {
          opacity: 1;
        }
      }

      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      .slide-meta__time {
        @include cta;
        text-transform: uppercase;
        margin-top: 4px;
      }

      .slide-meta__name {
        height: $spacing-xxl-5;
        margin-top: $spacing-m;

        .meta__name {
          @include body;
          max-width: 70%;
        }
        .meta__price {
          @include cta;
          margin-top: $spacing-xs;
          text-transform: capitalize;
        }
      }

      .slide-meta__thumb {
        position: relative;
        padding-bottom: 120%;
        background-position: center;
        background-size: cover;

        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }

        &-small {
          display: flex;
          justify-content: center;

          img {
            width: 60% !important;
          }
        }
      }

      .slide-meta__cta {
        @include cta-white;
        position: absolute;
        bottom: $spacing-s;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 48px);
        height: 36px;
        text-align: center;
        line-height: 36px;
        text-transform: uppercase;
        background-color: $green;
        opacity: 0;
        transition: $anime-300;
      }

      .slide-meta__flag {
        @include cta-white;
        position: absolute;
        top: 50%;
        left: -119px;
        height: $spacing-xl;
        width: 238px;
        text-align: center;
        line-height: $spacing-xl;
        text-transform: uppercase;
        background-color: $black;
        transform: rotate(-90deg) translate(calc(-#{$spacing-xl}), 50%);
        transition: $anime-300;
      }
    }
  }

  .v65-productGroup {
    .v65-productGroup-title,
    .v65-product-reviewStats,
    .v65-clear {
      display: none;
    }

    .v65-addToCart {
      legend,
      .v65-product-addToCart-quantity,
      .v65-product-addToCart-button,
      .v65-product-addToCart-unitDescription,
      .v65-product-addToCart-productSKU {
        display: none;
      }
      .v65-product-addToCart-price {
        &:before {
          display: inline;
          content: "PURCHASE • ";
        }
        .v65-product-addToCart-originalPrice {
          display: inline;
          text-decoration: line-through;
          opacity: 0.3;
        }
        .v65-product-addToCart-salePrice {
          display: inline;
        }
      }
    }

    .v65-productGroup-products {
      display: grid;
      grid-template-columns: repeat(1, 100%);
      @media #{$medium-up} {
        grid-template-columns: repeat(2, 50%);
      }
      .v65-productGroup-product {
        @include h2;
        @media #{$medium-up} {
          padding: 12px 24px;
          &:nth-child(odd) {
            border-right: 1px solid #d6d6d6;
          }
        }
        @media #{$large-up} {
          border-right: 1px solid #d6d6d6;
        }
        .v65-product-teaser {
          display: none;
        }
        .v65-product-title {
          position: relative;
          top: 18px;
          a {
            font-family: "Portrait", "serif";
            font-size: 18px;
            font-weight: 300;
            line-height: 1.44;
            letter-spacing: 0.01em;
          }
        }
        .v65-product-subtitle {
          color: #000;
          position: relative;
          top: -54px;
        }
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        overflow: hidden;
        margin-bottom: $spacing-xxl-3;
        .v65-product-quickView {
          display: none !important;
        }
        .v65-product-photo {
          margin-top: 10px;
          a {
            display: block;
            text-align: center;
          }
        }
        .v65-product-addToCart {
          @include cta-white;
          position: absolute;
          bottom: $spacing-s;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 48px);
          height: 36px;
          text-align: center;
          line-height: 36px;
          text-transform: uppercase;
          background-color: $green;
          opacity: 0;
          transition: $anime-300;
          cursor: pointer;
          fieldset {
            border: none;
            margin: 0;
            padding: 0;
          }
        }
        &:hover {
          .v65-product-addToCart {
            opacity: 1;
          }
        }
        .v65-product-subtitle a {
          @include cta;
          text-transform: uppercase;
          margin-top: 4px;
        }
      }
    }
  }

  .blogWidgetWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 64px;
    .v65-blogPostWrapper {
      width: 50%;
      padding: 12px 24px 12px 0px;
      .v65-avatar,
      .v65-clear,
      .v65-blogFooter {
        display: none !important;
      }
      .v65-blogPost {
        .v65-blogPostDate {
          color: #000;
          font-family: "Portrait", "serif";
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.13px;
          line-height: 1.43;
          text-align: center;
          width: 100%;
          display: none;
        }
        h2 {
          color: #000;
          font-size: 22px;
          font-weight: 300;
          letter-spacing: 0.2px;
          margin-top: 4px;
          line-height: 1.44;
          text-align: center;
          width: 100%;
        }
        p {
          color: #000;
          font-family: "Portrait", "serif";
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.13px;
          line-height: 1.43;
          a {
            padding-top: 1em;
            display: block;
          }
        }
      }
    }
  }

  .zoom-twin {
    &-card {
      .card-meta {
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-title {
          @include h2;
          text-transform: uppercase;
          margin-top: $spacing-xxl-3;
          margin-bottom: $spacing-xl;
        }

        .card-thumb,
        .card-text {
          display: none;
        }

        .card-cta {
          @include label-white;
          bottom: 0;
          display: block;
          width: 200px;
          height: 36px;
          background-color: $black;
          text-transform: uppercase;
          text-align: center;
          line-height: 36px;
        }
      }
    }

    &-splash {
      width: 100%;

      .splash-thumb {
        height: 398px;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .slides-twin-scroll {
    display: flex;

    > div {
      width: 100%;
    }

    .sts-scroll {
      section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        .sts-scroll__content-wrap {
          width: 320px;
          margin: 0;

          &__title {
            margin-top: $spacing-xxl-6 !important;
          }

          .scroll-text-subsection {
            h4 {
              padding-bottom: $spacing-s;
              border-bottom: 1px solid $line-grey;
              margin-top: 0;
              margin-bottom: $spacing-xxs;
            }

            h3 {
              margin-top: $spacing-s;
              margin-bottom: $spacing-s;
            }

            > a {
              display: flex;
              align-items: center;

              .prod-link__arrow {
                width: 16px;
                height: 16px;
                background-image: url("/assets/images/icons/bow-arrows/bow-arrow_down_black.svg");
                background-size: contain;
                margin-left: $spacing-xs;
                transform: rotate(-90deg);
              }
            }

            .prod-tasting-notes {
              > div {
                display: flex;
                padding: $spacing-s 0;
                border-bottom: 1px solid $line-grey;

                > span {
                  @include cta;
                  width: 120px;
                  text-transform: uppercase;
                }

                p {
                  @include body-small;
                  width: calc(100% - 120px);
                  margin: 0;
                }
              }
            }

            .prod-year-table {
              .dropdown-year-table {
                width: 100%;
                height: 40px;
                margin: $spacing-xxl-3 0 $spacing-xl;

                input,
                ul li,
                option {
                  text-transform: uppercase;
                }
              }

              &__tabs {
                display: none;
              }

              &__content {
                position: relative;
                min-height: 180px;

                p {
                  @include body-small;
                  position: absolute;
                  visibility: hidden;
                  opacity: 0;
                  transition: $anime-200;
                }

                &-show {
                  visibility: visible !important;
                  opacity: 1 !important;
                }
              }
            }
          }
        }
      }

      &__award-section {
        position: relative;
        flex-direction: column;
        align-items: flex-start !important;
        cursor: url("/assets/images/icons/cursors/cursor_white.svg"), auto;

        &__wrap {
          position: relative;
          width: 100%;
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: $spacing-xxl-2 $spacing-xl;
          background-color: $green;

          .wine-award {
            position: absolute;
            top: $spacing-xxl-4;
          }

          p {
            @include h3-caps-white;
            width: 100%;
            text-transform: uppercase;
            margin-bottom: $spacing-m;
          }

          span {
            @include cta-white;
          }
        }
      }
    }

    .sticky-placeholder {
      display: none;
    }
  }

  .h1-h2 {
    // For desktop
    @media #{$xlarge-up} {
      @include h1;
      width: 60%;
    }
    @media #{$large-only} {
      @include h1;
      width: 90%;
    }
    // For mobile
    @media #{$small-medium} {
      @include h2;
      width: 80%;
    }
    text-align: center;
  }
}
