// For desktop
@media #{$large-up} {
  .privacy-policy__title {
    margin-top: 0;
    margin-bottom: $spacing-xxl-7;
  }
}

// For mobile
@media #{$small-medium} {
  .privacy-policy__title {
    margin-top: $spacing-xxl-3;
    margin-bottom: $spacing-xxl-5;
  }

  .privacy-policy__items {
    padding-left: $spacing-l;
  }
}
