// For desktop
@media #{$large-up} {
  .sideNavLayout-hero {
    &__caption {
      max-width: 650px;
      font-size: 66px !important;
      letter-spacing: -2.5px !important;
    }
  }

  #fixable-side-nav {
    display: revert !important;

    ul {
      position: absolute;
      top: calc(100vh + #{$spacing-xxl-6});

      a {
        transition: $anime-200;
      }
    }
  }

  .fixed-side-nav {
    position: fixed !important;
    top: $spacing-xxl-6 !important;
  }

  .sideNavLayout-content {
    padding: 0 $spacing-xl $spacing-xxl-6 378px;

    .top-accordion {
      .sideNavLayout-accordion__body {
        border-top: none !important;
      }
    }

    .sideNavLayout-accordion {
      &__header {
        display: none !important;
      }

      &__body {
        padding: $spacing-xxl-6 0 $spacing-xxl-8 !important;
        border-top: 1px solid $line-grey;
        border-bottom: none !important;

        > h2 {
          width: 380px;
        }

        p {
          width: 375px;
          font-size: 18px !important;
          line-height: 1.44 !important;
          letter-spacing: normal !important;
        }

        figure {
          img {
            width: 100% !important;
            margin-left: unset !important;
          }

          figcaption {
            display: revert !important;
            margin-top: $spacing-s;
          }
        }

        &-declaration {
          display: flex;
          justify-content: center;
          
          h2 {
            width: 600px;
          }
        }

        &-figure {
          display: flex;
          margin-top: $spacing-xxl-6;

          > div {
            width: 50%;
          }

          &-photo {
            display: flex;
            margin: 0 !important;

            figure {
              margin: 0;

              img {
                width: 100% !important;
              }
            }
          }

          &-caption {
            display: flex;
            justify-content: center;

            > div {
              max-width: 380px;
              width: 80%;

              p {
                width: 100%;
              }
            }
          }

          .left-caption {
            justify-content: flex-start !important;
          }
          .right-photo {
            justify-content: center !important;
          }
        }
      }

      &__body-fold {
        display: revert !important;
      }
    }
  }
}

// For mobile
.sideNavLayout-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center;

  &__caption {
    @include h2-white;
    width: 85%;
    text-transform: uppercase;
    margin: 0;
  }
}

#fixable-side-nav {
  display: none;
}

.sideNavLayout-content {
  .no-top-margin {
    margin-top: 0 !important;
  }
  .no-bottom-margin {
    margin-bottom: 0 !important;
  }

  .sideNavLayout-accordion {
    &__header {
      @include cta;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      line-height: 50px;
      text-transform: uppercase;
      padding: 0 $spacing-xl;
      border-bottom: 1px solid $line-grey;

      &-arrow {
        width: $spacing-xl;
        height: $spacing-xl;
        background: transparent url('/assets/images/icons/spear-arrows/spear-arrow_down_black.svg') no-repeat center;
        transition: $anime-500;

        &-open {
          transform: rotate(-180deg);
        }
      }
    }

    &__body {
      padding: $spacing-xxl-6 $spacing-xl;
      border-bottom: 1px solid $line-grey;

      &.sideNavLayout-accordion__body-fold {
        border-bottom: none;
      }

      > h2 {
        text-transform: uppercase;
        margin-top: 0;
      }

      p {
        @include body-small;
      }

      figure {
        overflow: hidden;
        margin: $spacing-xxl-6 0;
    
        img {
          width: 140%;
          margin-left: -20%;
        }

        figcaption {
          display: none;
        }
      }

      &-declaration {
        h2 {
          text-align: center;
          text-transform: uppercase;
          margin: 0;
        }
      }

      &-figure {
        &-photo {
          margin: $spacing-xxl-6 0;

          figure {
            margin: 0;

            img {
              width: 100% !important;
              margin-left: 0 !important;
            }
          }
        }

        &-caption {
          > div {
            h2 {
              text-transform: uppercase;
              margin-top: 0;
            }
            
            p {
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  
    &__body-fold {
      display: none;
    }
  }
}
