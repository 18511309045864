// For desktop
@media #{$large-up} {
  .cart-action {
    &__gap {
      margin-right: $spacing-xxl !important;
    }

    &__quantity {
      width: auto !important;
      height: 24px !important;
      
      > div {
        width: 24px !important;
      }
      
      &-remove {
        background-position: center !important;
      }
      &-amount {
        font-size: 14px !important;
        line-height: 24px !important;
        border-right: 1px solid $line-grey;
        border-left: 1px solid $line-grey;
      }
      &-add {
        background-position: center !important;
      }
    }
  }

  .prod-within-range {
    > div {
      max-width: 1024px;
      margin: 150px 0 !important;

      h3 {
        margin-top: 0;
        margin-bottom: $spacing-xxl-3;
      }

      > div {
        flex-direction: row !important;
      }
    }
  }

  .dropdown {
    border: 1px solid $line-grey;

    &-current {
      display: flex !important;
      align-items: stretch;
      height: 100%;
      cursor: pointer;
      
      input {
        display: flex;
        align-items: center;
        width: calc(100% - 30px);
        height: 100% !important;
        text-transform: capitalize;
        padding-left: $spacing-xs !important;
        background-color: transparent;
        border: none !important;
        cursor: pointer;
      }
      
      &__arrow {
        width: 30px;
        background: transparent url('/assets/images/icons/spear-arrows/spear-arrow_down_black.svg') no-repeat center;
        transition: $anime-300;

        &-open {
          transform: rotate(180deg);
        }
      }
    }
    
    ul {
      position: absolute;
      z-index: 200;
      display: none;
      width: calc(100% + 2px);
      max-height: 260px;
      overflow-y: auto;
      list-style-type: none;
      text-transform: capitalize;
      background-color: $white;
      padding: 0;
      border-right: 1px solid $line-grey;
      border-bottom: 1px solid $line-grey;
      border-left: 1px solid $line-grey;
      margin: 0;
      margin-top: 1px;
      margin-right: -1px;
      margin-left: -1px;
  
      &.dropdown__show-list {
        display: block;
        -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
      }
  
      li {
        display: flex;
        align-items: center;
        height: 32px;
        padding-left: $spacing-xs;
        text-transform: capitalize;
        cursor: pointer;
  
        &:hover {
          background-color: $light-grey;
        }
      }

      .selected-item {
        background-color: $light-grey;
      }
    }

    select {
      display: none !important;
    }

    &-mobile-arrow {
      display: none;
    }
  }
}

// For mobile
.dropdown {
  @include cta;
  position: relative;
  // background-color: $white;

  &-current {
    display: none;
  }

  ul {
    display: none;
  }

  select {
    position: relative;
    z-index: 250;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    background-color: transparent;
    // Cancel native styles on Android and iOS
    -webkit-appearance: none;
    // Re-style for Android and iOS
    line-height: 32px;
    padding: 0 $spacing-xs;
    border: 1px solid $line-grey;
    border-radius: 0;

    &:focus {
      outline: none;
    }

    .option-hide {
      display: none;
    }
  }
  
  &-mobile-arrow {
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    width: 30px;
    height: 32px;
    background: transparent url('/assets/images/icons/spear-arrows/spear-arrow_down_black.svg') no-repeat center;
  }
}

.cart-action {
  display: flex;
  align-items: center;

  &__gap {
    margin-right: 0;
  }

  &__option {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-plus {
      width: 24px;
      height: 24px;
      background: $white url('/assets/images/icons/symbols/plus_grey.svg') no-repeat center;
      border: 1px solid $line-grey;
      margin-right: $spacing-xs;

      .option-selected & {
        background-image: url('/assets/images/icons/symbols/plus_white.svg');
        background-color: $green;
        border-color: $green;
      }
    }

    > span {
      @include body-small;
    }
  }

  &__quantity {
    display: flex;
    width: 105px;
    height: 40px;
    
    > span {
      @include body-small;
      margin-right: $spacing-xs;
    }
    
    > div {
      width: calc(100% / 3);
      color: $mid-grey;
    }
    
    &-remove {
      background: $white url('/assets/images/icons/symbols/minus_grey.svg') no-repeat right center;
      border-top: 1px solid $line-grey;
      border-bottom: 1px solid $line-grey;
      border-left: 1px solid $line-grey;
      cursor: pointer;
    }
    &-amount {
      font-family: $bond4f;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      background-color: $white;
      border-top: 1px solid $line-grey;
      border-bottom: 1px solid $line-grey;
      user-select: none;
      cursor: not-allowed;
    }
    &-add {
      background: $white url('/assets/images/icons/symbols/plus_grey.svg') no-repeat left center;
      border-top: 1px solid $line-grey;
      border-right: 1px solid $line-grey;
      border-bottom: 1px solid $line-grey;
      cursor: pointer;
    }
  }

  &__purchase {
    @include cta;
    width: 250px;
    min-height: 36px;
    text-align: center;
    color: $white;
    background-color: $green;
    border: none;
    user-select: none;
    cursor: pointer;

    &.out-of-stock {
      background-color: $mid-grey;
    }

    del {
      color: #47a372;
      margin-left: $spacing-xxs;
    }
  }
}

.wine-award {
  &__stars {
    display: flex;
    width: 200px;
    height: 30px;
    
    &-center {
      justify-content: center;
    }
    &-right {
      justify-content: right;
    }

    span {
      width: 18px;
      height: 18px;
      margin-right: $spacing-xxs;
      
      &:last-child {
        margin-right: 0;
      }
    }
    
    .white-star {
      background: transparent url('/assets/images/icons/stars/star_white.svg') no-repeat center;
      background-size: contain;
    }
    .black-star {
      background: transparent url('/assets/images/icons/stars/star_black.svg') no-repeat center;
      background-size: contain;
    }
  }

  &__title {
    @include cta;
    text-transform: uppercase;
  }
}

.gift-card-white {
  @include gift-card($white);
}

.gift-card-black {
  @include gift-card($black);
}

.back-to {
  @include body-small;
  position: relative;
  height: $spacing-xxl-3;
  line-height: $spacing-xxl-3;
  padding: 0 24px;
  background-color: $cream-tint;

  &-prev {
    padding-left: $spacing-m;
    background: transparent url('/assets/images/icons/spear-arrows/spear-arrow_left_black.svg') no-repeat left center;
  }
}

.prod-within-range {
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: $spacing-xxl-4;

    h3 {
      text-transform: uppercase;
      text-align: center;
    }

    > div {
      display: flex;
      flex-direction: column;
      
      .prod-within-range__slide {
        display: flex;
        flex-direction: column;

        img {
          width: 100%;
        }

        span {
          &:nth-child(2), &:last-child {
            @include cta;
            text-transform: uppercase;
          }
          &:nth-child(3) {
            margin-top: $spacing-m;
            margin-bottom: $spacing-xxs;
          }
        }
      }
    }
  }
}

.cta-button-black {
  @include cta-white;
  display: block;
  width: 200px;
  height: 36px;
  text-align: center;
  text-transform: uppercase;
  line-height: 36px;
  padding: 0;
  background-color: $black;
  margin: $spacing-xxl auto 0;
  @media #{$medium-up} {
    margin-left: initial;
    margin-right: initial;
  }
  cursor: pointer;
}

.cta-button-white {
  @include cta;
  display: block;
  width: 200px;
  height: 36px;
  text-align: center;
  text-transform: uppercase;
  line-height: 36px;
  padding: 0;
  background-color: $white;
  margin: $spacing-xxl auto 0;
  @media #{$medium-up} {
    margin-left: initial;
    margin-right: initial;
  }
  cursor: pointer;
}

@media #{$large-up} {
  .side-nav {
    &__title {
      display: none;
    }

    &__dropdown {
      display: none;
    }

    &__sidebar {
      @include cta;
      position: fixed;
      z-index: 300;
      left: 0;
      top: $spacing-xxl-8;
      display: block !important;
      list-style-type: none;
      padding-top: $spacing-m;
      padding-right: $spacing-s;
      background-color: $cream-tint;
      margin: 0;
      
      li {
        position: relative;
        margin-bottom: $spacing-s;

        &.side-nav__selected {
        
          &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: -$spacing-m;
            width: 1px;
            height: $spacing-l;
            background-color: $black;
          }

          a {
            color: $black !important;
          }
        }

        a {
          color: $mid-grey;
          text-transform: uppercase;
        }
      }
    }
  }
}

.side-nav {
  &__title {
    text-align: center;
  }

  &__dropdown {
    width: 100%;
    height: 40px;
  }

  &__sidebar {
    display: none;
  }
}

.card-size-xl {
  padding-bottom: 133.33%;
}
.card-size-l {
  padding-bottom: 123.53%;
}
.card-size-m {
  padding-bottom: 92.16%;
}
.card-size-s {
  padding-bottom: 72.55%;
}
