$orange: #fcb401;

.wine-club__hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 55.55%;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $orange;
  position: relative;
  font-family: "Circular", sans-serif;

  &-top-section {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    bottom: 50px;
  }

  &-subtext {
    font-family: "Circular", sans-serif;
    font-weight: 300;
    margin: 0;
    font-size: 22px;
    line-height: 1.2em;
    letter-spacing: 0.03em;
    color: black;
    margin-bottom: 60px;
    max-width: 326px;
  }

  &-sublabel {
    font-family: "Circular", sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 16px;
  }

  &-btn {
    background-color: black;
    color: white;
    width: 130px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    size: 13px;
    font-weight: 500;
    letter-spacing: 0.9px;
    font-family: "Bond4F";
  }
}

.wine-club__invite {
  display: flex;
  justify-content: center;
  font-family: "Circular", sans-serif;
  flex-wrap: wrap;

  &-fifty-section {
    width: 50%;
    &-wrapper {
      padding: 90px 100px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      height: 100%;
    }

    &-intro {
      @include body-small;
      text-align: left;
      font-size: 22px;
      line-height: 1.5;
      font-family: "Circular", sans-serif;
      margin-bottom: 100px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-section-table {
    width: 100%;
    &-row {
      display: flex;
      justify-content: flex-start;
      border-top: 1px solid black;
      padding: 24px 0px;

      &-label {
        width: 100px;
      }
    }
  }
}

.wine-club__fold {
  padding: 130px 90px;
  // background-color: $orange;
  font-family: "Circular", sans-serif;
  position: relative;

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(320px, 1fr));
    grid-gap: 1rem;
  }

  .prev {
    position: absolute;
    top: 50%;
    left: 0px;
    display: none;
  }

  .next {
    position: absolute;
    top: 50%;
    right: 0px;
    display: none;
  }

  &-card {
    margin: 0px;
    border: 1px solid black;
    padding: 80px 40px 40px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    &:hover {
      background-color: $orange;
      border: 1px solid white;

      h2,
      p,
      div,
      li {
        color: black;
      }

      .cta-button-black {
        background-color: $black;
        color: $white;
      }
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    h2 {
      font-family: "Circular", sans-serif;
      text-transform: uppercase;
      line-height: 0.91;
      letter-spacing: 0.5px;
      font-size: 28px;
      font-weight: normal;
      margin: 0px 0px 17px 0px;
    }

    .discount {
      margin-bottom: 17px;
      font-weight: 400;
    }

    p {
      font-family: "Circular", sans-serif;
      max-width: 302px;
      font-size: 18px;
      margin-bottom: 67px;
      height: 105px;
      display: flex;
      align-items: center;
    }

    ul {
      list-style: none;
      margin: 0 0 45px 0;
      padding: 0;
      width: 100%;
      font-weight: 300;
    }

    &-term,
    li {
      width: 100%;
      padding: 10px 0;
      border-top: 1px solid black;
      text-align: left;
    }

    &-signup {
      width: 100%;
    }
  }
}

.slick-dots li button:before {
  width: 15px !important;
  height: 15px !important;
  content: "" !important;
  border: 1px solid !important;
  border-radius: 50% !important;
  opacity: 1 !important;
  color: black !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  background-color: black !important;
}

.slick-dots {
  bottom: -50px !important;
}

.wine-club__included {
  padding-top: 32.5%;
  background-size: cover;
  border: 1px solid $orange;
}

.wine-club__contact {
  display: flex;
  flex-wrap: wrap;

  &-fifty-section {
    display: flex;
    width: 50%;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .background {
      width: 100%;
    }

    .icon {
      position: absolute;
      right: -65px;
      top: 75px;
    }
  }

  &-fifty-section-info {
    max-width: 326px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    font-family: "Circular", sans-serif;
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 1300px) {
  .wine-club__invite-fifty-section {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .wine-club__fold {
    padding: 130px 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .wine-club__hero {
    padding-top: 0;
    height: 800px;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fcb401;
    background-image: url("/assets/images/wine_club/top-hero-mobile.svg") !important;
    background-position: center;

    &-top-section {
      bottom: 50px;
    }
  }

  .wine-club__fold {
    padding: 95px 28px;

    &-card {
      padding: 70px 28px 28px 28px;
      margin: 0px 20px;
    }
    .prev,
    .next {
      display: block;
    }
  }

  .wine-club__included {
    background-image: url("/assets/images/wine_club/club-lower-mobile.svg") !important;
    padding-top: 0;
    height: 385px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $orange;
  }
}

@media only screen and (max-width: 900px) {
  .wine-club__contact-fifty-section {
    width: 100%;
  }

  .wine-club__contact-fifty-section:nth-of-type(2) {
    min-height: 600px;
    padding: 0px 55px;
  }

  .icon {
    right: 50px !important;
    bottom: -110px;
    top: auto !important;
    width: 150px;
  }
}

@media only screen and (max-width: 700px) {
  .wine-club__invite-fifty-section-wrapper {
    padding: 90px 50px;
  }

  .wine-club__invite-section-table-row {
    display: flex;
    flex-wrap: wrap;
  }
  .wine-club__invite-section-table-row-label {
    margin-bottom: 10px;
  }
  .wine-club__invite-section-table-row-content {
    min-width: 300px;
  }
}

@media only screen and (max-width: 480px) {
  .wine-club__fold {
    &-card {
      margin: 0px;
    }
  }

  .wine-club__invite-fifty-section-wrapper {
    padding: 90px 20px;
  }
}
