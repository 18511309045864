// For desktop
@media #{$large-up} {
  .account-wrap {
    .account-nav {
      &__title {
        display: none;
      }

      &__dropdown {
        display: none;
      }

      &__sidebar {
        @include cta;
        position: fixed;
        z-index: 290;
        left: 0;
        top: $spacing-xxl-8;
        display: block !important;
        list-style-type: none;
        padding-top: $spacing-m;
        padding-right: $spacing-s;
        padding-bottom: $spacing-m;
        background-color: $cream-tint;
        margin: 0;

        li {
          position: relative;

          &.account-nav__selected {
            &::before {
              content: "";
              position: absolute;
              top: -5px;
              left: -$spacing-m;
              width: 1px;
              height: $spacing-l;
              background-color: $black;
            }

            a {
              color: $black !important;
            }
          }

          &:last-child a {
            padding-bottom: 0;
          }

          a {
            display: block;
            color: $mid-grey;
            padding-bottom: $spacing-s;

            &:hover {
              color: $black;
            }
          }
        }
      }

      .account-nav__top-gap {
        display: none;
      }
    }

    .account-content {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 600px;
      margin-top: $spacing-xxl-6;

      &__caption {
        padding: 0 !important;
        margin-right: 10%;
        margin-left: 10%;
      }
    }
  }
}

// For mobile
.account-wrap {
  flex: 1;
  background-color: $cream-tint;
  padding: $spacing-xxl-5 $spacing-xl $spacing-xxl-6;

  .account-nav {
    &__title {
      text-align: center;
    }

    &__dropdown {
      width: 100%;
      height: 40px;
    }

    &__sidebar {
      display: none;
    }

    .account-nav__top-gap {
      height: $spacing-xxl-3;
    }
  }

  .account-content {
    > p:first-of-type {
      margin-top: 0;
    }
    > p:last-of-type {
      margin-bottom: 0;
    }

    &__title {
      text-align: center;
      margin: $spacing-xxl-3 0 $spacing-xs;
    }

    &__caption {
      @include body-small;
      text-align: center;
      padding: 0 15%;
      margin-top: 0;
      margin-bottom: $spacing-xl;
    }
  }

  .dropdown {
    .dropdown-mobile-arrow {
      height: 40px;
    }
  }
}

.join-us {
  .v65-customForm {
    display: flex;
    justify-content: center;
  }
}
