// For desktop
@media #{$large-up} {
  .our-people__caption {
    height: 300px !important;
    justify-content: flex-end !important;

    p {
      width: 450px;
    }
  }

  .our-people__owner {
    display: flex;
    height: 100vh;

    > div {
      width: 50% !important;
    }

    &-photo {
      img {
        width: 330px !important;
      }
    }

    &-caption {
      > div {
        width: 380px !important;
      }
    }
  }

  .our-people__hero {
    margin: 0 $spacing-xl !important;
    width: revert !important;
    height: 100vh !important;
  }

  .our-people__staff {
    grid-template-columns: repeat(2, 50%) !important;
    padding-top: $spacing-xxl-9 !important;
    padding-bottom: $spacing-xxl-9 !important;
    margin-right: $spacing-xl;
    margin-left: $spacing-xl;

    > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing-xl;
      width: 100%;
  
      &:nth-child(odd) {
        padding-right: $spacing-s;
      }
      &:nth-child(even) {
        padding-left: $spacing-s;
      }
    }
  
    &-photo {
      width: 50% !important;
    }

    &-caption {
      width: 50% !important;
      display: flex;
      justify-content: flex-end;
      padding-left: $spacing-m;
      margin: 0 !important;
  
      > div {
        max-width: 270px;
      }
    }
  }
}

// For mobile
.our-people__caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 400px;
  margin-right: 5%;
  margin-left: 5%;

  h1 {
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }
}

.our-people__owner {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-photo {
    img {
      width: 100%;
    }
  }

  &-caption {
    > div {
      margin: $spacing-xxl-5 $spacing-xl;
      width: 100%;

      h4 {
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: $spacing-xs;
      }
      
      span {
        @include cta;
        text-transform: uppercase;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.our-people__hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vw;
  background-image: url('/assets/images/our_people/VE_OurPeople_EstateSunset_GAV_0097.jpg');
  background-size: cover;
  background-position: center;
  margin: 0 $spacing-xl;
}

.our-people__staff {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  padding-top: $spacing-xxl-6;
  padding-bottom: $spacing-xxl-6;

  &-photo {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &-caption {
    margin: $spacing-xxl-2 $spacing-xl $spacing-xxl-5;

    > div {
      h4 {
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: $spacing-xs;
      }
      
      span {
        @include cta;
        text-transform: uppercase;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
