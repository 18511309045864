// For desktop
@media #{$large-up} {
  .nav {
    .nav__commerce {
      .nav__options {
        li {
          &:last-child {
            display: none;
          }
        }
      }
    }
    &__options {
      display: flex !important;
      z-index: inherit;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        padding-right: $spacing-xxl;

        & > div:first-child {
          padding-right: $spacing-xxl;
        }

        a {
          padding: $spacing-s 0;
          @include cta-white;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          transition: $anime-500;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-dark {
        li a {
          color: $black !important;
        }
      }
    }

    &__hamburger {
      display: none !important;
    }
  }
}

#v65-toggleModalCart {
  font-family: "Bond4F","sans-serif";
  font-size: 0;
  color: transparent;
  strong {
    font-size: 13px;
    color: black;
    &:first-child {
      &:after {
        content: ") ";
        display: inline;
      }
      &:before {
        content: "(";
        display: inline;
      }
    }
  }
}

#nav-hollow {
  &.nav-cream-bg {
    #v65-toggleModalCart {
      strong {
        color: black;
      }
    }
  }
  #v65-toggleModalCart {
    strong {
      color: white;
    }
  }
}

#nav-option-search {
  display: none;
  input {
    width: 100%;
    background-color: transparent;
    color: $dark-mid-grey;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $body-font;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.8px;
    line-height: normal !important;
    padding: $spacing-s 0 !important;
    border: none !important;
    border-bottom: 1px solid $black !important;
    outline: none;
  }
  fieldset {
    border: none;
    button {
      @include cta-white;
      span {
        @include cta-white;
      }
      display: block;
      width: 100%;
      height: 36px;
      text-align: center;
      text-transform: uppercase;
      line-height: 36px;
      padding: 0;
      background-color: $black;
      margin-top: $spacing-xxl;
      cursor: pointer;
    }
  }

}

#v65-modalCartDropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0 1rem 1rem;
  width: 100%;
  max-width: 450px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.11);
  background-color: white;
  table {
    width: 100%;
    th, td {
      text-align: left;
    }
  }
  #v65-modalCartFooter {
    display: none;
  }
  .modalLinkAltBtn:nth-child(2), .modalLinkBtn {
      span {
        @include cta-white;
      }
      display: block;
      width: 100%;
      height: 36px;
      text-align: center;
      text-transform: uppercase;
      line-height: 36px;
      padding: 0;
      background-color: $black;
      margin-top: $spacing-xxl;
      cursor: pointer;
  }
  .right:first-child {
    padding: 0;
    margin: 0;
    text-align: right;
    span {
      display: none;
    }
    a {
      &:after {
        content: "×";
        display: inline;
        color: black;
        font-size: 28px;
      }
    }
  }
  .right {
    text-align: right;
  }
}

// For mobile
.nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $spacing-xxl-5;
  padding: $spacing-s $spacing-m;
  transition: $anime-500;

  &__hide {
    top: -$spacing-xxl-5 !important;
  }

  &-cream-bg {
    background-color: $cream-tint;
  }

  &__hamburger {
    display: block;
    cursor: pointer;

    &-icon {
      position: relative;
      display: flex;
      width: 24px;
      height: 24px;
      background-color: transparent;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-start;
      text-indent: -9999px;
      padding: 7px 5px;
      border: none;
      outline: none;
      cursor: pointer;

      &::before, &::after {
        content: '';
        position: absolute;
        display: block;
        width: 14px;
        height: 1px;
        transition: $anime-300;
        border-top: 1px solid #fff;
      }

      &::before {
        top: 7px;
      }
      &::after {
        bottom: 7px;
      }

      &-dark {
        &::before, &::after {
          border-top: 1px solid #000 !important;
        }
      }

      .active-navigation & {
        &::before {
          top: 11px;
          transform: rotate(45deg);
        }

        &::after {
          bottom: 11px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__hamburger-close, &__search-menu-close, &__minicart-menu-close {
    width: 24px;
    height: 24px;
    line-height: revert !important;
    background-color: transparent;
    padding: 0;
    border: none;
    margin: 0;
    overflow: hidden;
    outline: none;
    cursor: pointer;
  }

  &__options {
    display: none;
  }

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    img {
      vertical-align: middle;
    }
  }

  &__commerce {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    #nav-flyout {
      position: absolute;
      top: -999px;
      left: -999px;
      display: block;
      background-color: #fff;
      list-style-type: none;
      padding: 0;
      margin: 0;
      -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
      -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
      box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
      
      li {
        text-align: center;
        padding: 8px 20px;
        
        a {
          @include cta;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: -$spacing-xxl-5;
        left: 0;
        display: block;
        width: 100%;
        height: $spacing-xxl-5;
        cursor: pointer;
      }
    }
  }

  &__bag {
    line-height: 1;
    padding-left: $spacing-xs;
    @include cta-white;

    &-dark {
      color: $black !important;
    }
  }

  &__minicart-option {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
