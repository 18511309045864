$height: 650px;

// For desktop
@media #{$large-up} {
  .not-found {
    &__modal {
      width: 620px !important;
      height: 374px !important;

      &-wrap {
        h1 {
          width: 40% !important;
        }
    
        p {
          width: 75% !important;
        }
      }
    }
  }
}

// For mobile
.not-found {
  position: relative;

  &__bg {
    @include min-viewport-height;
    height: $height;
    background: url('/assets/images/garden_philosophy/desktop/VE_Garden_2-11-20_0872.jpg') no-repeat center;
    background-size: cover;
  }

  &__mask {
    @include min-viewport-height;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $height;
    background-color: $black;
    opacity: .6;
  }

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 374px;
    text-align: center;
    background-color: $white;

    display: flex;
    justify-content: center;
    align-items: center;

    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        @include h3-caps;
        margin: 0;
        width: 75%;
        text-transform: uppercase;
      }
  
      p {
        @include body;
        margin: $spacing-s 0 0;
        width: 80%;
      }
    }
  }
}
