.member-section {
  display: flex;
  flex-wrap: wrap;

  .left-section {
    min-width: 220px;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        margin-bottom: 10px;

        &.allocations,
        &.allocation-history {
          display: none;
        }
      }
    }
  }

  .right-section {
    @media #{$small-medium} {
      min-width: 100%;
      margin-top: 10px;
    }

    flex: 1;

    h1 {
      @include h2;
      font-family: $portrait;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: 300;
      letter-spacing: 0.2px;

      + h1 {
        @include h3;
        text-transform: none;
      }
    }
    h2 {
      @include h3;
    }

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p {
      margin-top: 0;
      margin-bottom: 12px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      text-align: left;

      td,
      th {
        padding: 8px;
      }
      th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #3f4445;
        color: white;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      tr:hover {
        background-color: #ddd;
      }
    }

    form {
      label {
        text-transform: uppercase;
      }
    }

    .orderDateRow {
      background-color: inherit;
      color: black;
    }

    .linkBtn {
      padding: 0 8px;
      background-color: #000;
      display: inline-block;
      align-items: center;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.9px;
      height: 36px;
      vertical-align: middle;
    }

    .linkBtn span {
      @include cta-white;
      display: inline-block;
      vertical-align: middle;
      line-height: 36px;
      width: 100%;
      text-align: center;
    }

    #v65-fieldset-paymentMethod,
    #v65-fieldset-shippingOptions,
    #v65-clubShippingInformation {
      select {
        width: 100% !important;
      }
    }

    #GiftMessage,
    #Notes {
      width: 100% !important;
    }

    #v65-fieldset-choices {
      max-width: none;
      width: 100%;

      .v65-clubChoicesProductsWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 1rem;
      }

      .v65-clubChoicesProduct {
        background-color: white;
        padding: 15px;
        box-sizing: content-box;
        margin-bottom: 1rem;
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid white;

        .v65-clubProductImageWrapper {
          display: flex;
          justify-content: center;
        }
      }

      .v65-clubChoicesProduct:hover {
        border: 1px solid #0f5e34;
      }
    }

    #v65-memberOrders {
      .v65-btn-reorder,
      td:nth-child(1),
      th:nth-child(1),
      td:nth-child(5),
      th:nth-child(5),
      td:nth-child(6),
      th:nth-child(6) {
        display: none;
      }
    }

    .v65-memberAllocationHistoryWrapper {
      padding-top: 40px;
    }

    .v65-memberAllcoationHistoryTitle {
      font-weight: bold;
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
}
