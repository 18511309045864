@mixin display {
  color: $black;
  font-family: $heading-font;
  font-size: 66px;
  font-weight: 300;
  letter-spacing: -2.5px;
  line-height: 1;
}

@mixin display-white {
  color: $white;
  font-family: $heading-font;
  font-size: 66px;
  font-weight: 300;
  letter-spacing: -2.5px;
  line-height: 1;
}

@mixin h1 {
  color: $black;
  font-family: $heading-font;
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 1;
}

@mixin h1-white {
  color: $white;
  font-family: $heading-font;
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 1;
}

@mixin h1-white-sans {
  color: $white;
  font-family: $cta-font;
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 1;
}

@mixin h2 {
  color: $black;
  font-family: $heading-font;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -1.14px;
  line-height: 1;
}

@mixin h2-white {
  color: $white;
  font-family: $heading-font;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -1.14px;
  line-height: 1;
}

@mixin h2-white-sans {
  color: $white;
  font-family: $cta-font;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -1.14px;
  line-height: 1;
}

@mixin h3 {
  color: $black;
  font-family: $body-font;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 0.2px;
}

@mixin h3-white {
  color: $white;
  font-family: $body-font;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 0.2px;
}

@mixin h3-caps {
  color: $black;
  font-family: $body-font;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 0.78px;
}

@mixin h3-caps-white {
  color: $white;
  font-family: $body-font;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 0.78px;
}

@mixin h4 {
  color: $black;
  font-family: $body-font;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.56px;
}

@mixin h4-white {
  color: $white;
  font-family: $body-font;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.56px;
}

@mixin body {
  color: $black;
  font-family: $body-font;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.44;
}

@mixin body-white {
  color: $white;
  font-family: $body-font;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.44;
}

@mixin body-small {
  color: $black;
  font-family: $body-font;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.13px;
  line-height: 1.43;
}

@mixin body-small-white {
  color: $white;
  font-family: $body-font;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.13px;
  line-height: 1.43;
}

@mixin cta {
  color: $black;
  font-family: $cta-font;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 0.92;
}

@mixin cta-white {
  color: $white;
  font-family: $cta-font;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 0.92;
}

@mixin label {
  color: $black;
  font-family: $cta-font;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

@mixin label-white {
  color: $white;
  font-family: $cta-font;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
}

@mixin sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@mixin sr-only-focusable {
  &:not(:focus) {
    @include sr-only();
  }
}

@mixin form($desktopWidth, $mobileWidth, $border-color, $font-color) {
  .form-wrap {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      @include h3;
      color: $font-color;
    }

    p {
      @include body-small;
      width: 340px;
      text-align: center;
      color: $font-color;
    }

    form {
      display: flex;
      flex-direction: column;

      @media #{$large-up} {
        width: $desktopWidth;
      }
      @media #{$small-medium} {
        width: $mobileWidth;
      }

      .form-element-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $spacing-m;
        margin-bottom: $spacing-m;

        a {
          @include body-small;
          color: $font-color;
          text-decoration: underline;
        }
      }
      .form-element-row-vertical {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: $spacing-m;
        margin-bottom: $spacing-m;

        .form-row-label {
          font-size: 14px;
          margin-bottom: $spacing-xs;
        }
      }
    }
  }

  .form-checkbox {
    @include body-small;
    position: relative;
    display: flex;
    align-items: center;
    height: 22px;
    text-transform: unset;
    padding-left: $spacing-xxl;
    margin: 0;
    user-select: none;
    color: $font-color;
    cursor: pointer;
  
    input {
      position: absolute;
      height: 0;
      width: 0;
      opacity: 0;
      cursor: pointer;
  
      &:checked {
        + span {
          &::after {
            display: block;
          }
        }
      }
    }
  
    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $border-color;
  
      &::after {
        content: "";
        position: absolute;
        display: none;
        top: 1px;
        left: 6px;
        width: 5px;
        height: 11px;
        border: solid $font-color;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
    }
  }

  .form-label {
    color: $font-color;
    text-transform: uppercase;
    margin-top: $spacing-l;
    margin-bottom: $spacing-xs;
  }

  .form-input {
    @include body-small;
    height: 42px;
    color: $font-color;
    padding-right: $spacing-s;
    padding-left: $spacing-s;
    background: transparent;
    border: 1px solid $border-color;

    &::placeholder {
      color: $font-color;
    }
  }

  .form-button {
    @include cta-white;
    height: 42px;
    color: $font-color;
    text-transform: uppercase;
    background: transparent;
    border: 1px solid $border-color;
    margin-top: $spacing-m;
    cursor: pointer;

    &__black {
      color: #fff;
      background-color: #000;
      border: none;
    }
  }

  .form-buttonlink {
    @include cta-white;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: $font-color;
    text-transform: uppercase;
    background: transparent;
    border: 1px solid $border-color;
    margin-top: $spacing-s;
    cursor: pointer;
  }
}

@mixin gift-card($color) {
  position: relative;
  @media #{$large-up} {
    width: 70% !important;
  }
  width: 80%;
  background-color: $color;
  @media #{$large-up} {
    padding-bottom: 40% !important;
  }
  padding-bottom: 45%;
  border-radius: 11px;

  img {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 60% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

// This is so that when the height of the page content
// is not enough to fill up the viewport vertically, the
// minimum height will be set to do so minus the footer.
@mixin min-viewport-height {
  @media #{$xlarge-up} {
    min-height: $footer-xlarge;
  }
  min-height: $footer;
}
