$sticky-cart-height: 104px;

// For desktop
@media #{$large-up} {
  .sticky-cart {
    position: fixed;
    z-index: 250;
    bottom: -$sticky-cart-height !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $sticky-cart-height;
    padding-right: $spacing-xl;
    background-color: $white;
    -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);

    &__item {
      display: flex !important;

      &-thumb {
        height: 104px;

        img {
          height: 104px;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__promo {
          font-style: italic;
          color: $mid-grey;
        }
      }
    }

    &__show {
      bottom: 0 !important;
    }

    .cart-action {
      flex-direction: row !important;
      width: auto !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;

      .cart-option-wrap {
        display: flex;
        width: auto !important;

        &__hover {
          height: 40px !important;
        }

        button {
          display: none;
        }

        .cart-action__option {
          border-top: none !important;
          padding: 0 !important;
          
          &-plus {
            margin-right: 0 !important;
          }
    
          span {
            display: block;
            font-size: 14px !important;
            letter-spacing: 0.13px;
            line-height: 1.43 !important;
            margin-left: $spacing-xs;
          }
        }
      }

      .cart-action-wrap {
        align-items: center;
        width: auto !important;

        .cart-action__quantity {
          span {
            display: block !important;
          }
        }

        .cart-action__purchase {
          width: 250px !important;
          height: 36px !important;
        }
      }
    }
  }
}

// For mobile
.sticky-cart {
  position: fixed;
  z-index: 250;
  bottom: 0;
  display: flex;
  background-color: $white;
  transition: $anime-500;

  &__item {
    display: none;
  }

  &__hide {
    bottom: -80px !important;
  }

  .v65-product-addToCart {
    width: 100%;
    fieldset {
      height: 40px;
      justify-content: center;
      .v65-product-addToCart-button button {
        min-height: 20px;
      }
    }
  }
  
  .cart-action {
    display: flex;
    flex-direction: column;
    width: 100vw;
    -webkit-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);

    .cart-option-wrap {
      width: 100%;
      height: 40px;
      transition: $anime-300;

      &__hover {
        height: 120px;

        button {
          background-image: url('/assets/images/icons/spear-arrows/spear-arrow_down_black.svg') !important;
        }
      }

      button {
        @include body;
        width: 100%;
        height: 40px;
        text-align: left;
        background-color: $white;
        padding-left: $spacing-xxl-2;
        background: transparent url('/assets/images/icons/spear-arrows/spear-arrow_up_black.svg') no-repeat $spacing-xs center;
        border: none;
      }

      .cart-action__option {
        height: 40px;
        padding: 0 $spacing-xs;
        border-top: 1px solid $line-grey;
        
        &-plus {
          margin-right: $spacing-s;
        }
  
        span {
          @include body;
        }
      }
    }

    .cart-action-wrap {
      display: flex;
      width: 100%;

      .cart-action__quantity {
        span {
          display: none;
        }
      }

      .cart-action__purchase {
        width: calc(100% - 105px);
        height: 40px;
      }
    }
  }
}
