// Common
.edit-profile-form {
  @include form(400px, 100%, $line-grey, $black);

  .form-wrap {
    // input {
    //   background-color: $white;
    // }

    .add-email-address {
      border-color: $black;
    }

    .dropdown-states {
      margin: 0;
      height: 42px;

      input, ul li, option {
        text-transform: uppercase;
      }
    }

    // .checkbox-tick-box {
    //   background-color: $white;
    // }
  }
}
