@font-face {
  font-family: 'Bond4F';
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: url('Bond4F-Medium.otf');
}

@font-face {
  font-family: 'Fleya-VoyagerMono';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('Fleya-Light-Mono--numbers.eot'); /* IE9 Compat Modes */
  src: url('Fleya-Light-Mono--numbers.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('Fleya-Light-Mono--numbers.woff2') format('woff2'), /* Super Modern Browsers */
       url('Fleya-Light-Mono--numbers.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Circular';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('circular-book.eot'),
       url('circular-book.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('circular-medium.eot'),
       url('circular-medium.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: url('circular-bold.eot'),
       url('circular-bold.woff') format('woff');
}

@font-face {
  font-family: 'Portrait';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('Portrait-Light.otf');
}

$body-font: 'Portrait', 'serif';
$heading-font: 'Fleya-VoyagerMono', 'serif';
$cta-font: 'Bond4F', 'sans-serif';

$bond4f: 'Bond4F', 'sans-serif';
$fleya: 'Fleya-VoyagerMono', 'serif';
$portrait: 'Portrait', 'serif';
