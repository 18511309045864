// For desktop
@media #{$large-up} {
  .modal {
    width: 690px !important;
    height: 485px !important;

    &-content {
      width: 320px !important;

      h4 {
        width: 470px !important;
        margin-top: 0 !important;
      }

      .form-checkbox {
        width: 320px !important;
      }
    }
  }
}

// For mobile
.modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  width: 95%;
  height: 485px;
  background-color: $white;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.4);
  transition: $anime-300;

  &-close {
    position: absolute;
    top: $spacing-m;
    right: $spacing-m;
    width: 24px;
    height: 24px;
    background-image: url('/assets/images/icons/symbols/close_grey.svg');
    cursor: pointer;
  }

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    
    h4 {
      width: 100%;
      text-transform: uppercase;
      margin-top: revert;
      text-align: center;
    }

    img {
      width: 108px;
      margin-bottom: $spacing-xs;
    }

    span {
      @include body-small;
      display: block;
      width: 100%;
      text-align: center;
    }

    input[type="text"] {
      width: 100%;
      height: 40px;
      padding-right: $spacing-xs;
      padding-left: $spacing-xs;
      margin-top: $spacing-xxl;
      border: 1px solid $line-grey;
    }

    .form-checkbox {
      @include body-small;
      position: relative;
      display: flex;
      width: 320px;
      height: auto;
      text-transform: unset;
      padding-left: $spacing-xxl;
      margin: $spacing-s 0 0;
      user-select: none;
      cursor: pointer;
    
      input {
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
        cursor: pointer;
    
        &:checked {
          + span {
            &::after {
              display: block;
            }
          }
        }
      }
    
      span {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid $black;
    
        &::after {
          content: "";
          position: absolute;
          display: none;
          top: 1px;
          left: 6px;
          width: 5px;
          height: 11px;
          border: solid $black;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
        }
      }
    }

    button {
      @include cta-white;
      width: 100%;
      height: 36px;
      text-transform: uppercase;
      background-color: $black;
      margin-top: $spacing-l;
      border: none;
      cursor: pointer;
    }
  }

  .active-modal & {
    visibility: visible;
    opacity: 1;
  }
}
