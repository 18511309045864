// For desktop
@media #{$large-up} {
  .community__hero {
    &-caption {
      max-width: 1120px;
      font-size: 66px !important;
      letter-spacing: -2.5px !important;
    }
  }

  .community__from {
    h2 {
      width: revert !important;
    }
    p {
      width: 470px !important;
    }
  }

  .community__plate {
    height: calc(100vh - 115px * 2) !important;
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
}

// For mobile
.community__hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center;

  &-caption {
    @include h2-white;
    padding: 10px;
    text-transform: uppercase;
    margin: 0;
  }
}

.community__from {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 120px 0;

  h2,
  p {
    margin: 0;
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: $spacing-xl;
  }

  p {
    @include body;
    width: 80%;
  }

  .cta-button-black {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.community__plate {
  height: 376px;
  background-size: cover;
  background-position: center;
}
